import React, { useEffect, useState } from "react";
import Sidebar from "../UI/Merchant/SideNav";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Add_Payment_Api,
  GateWay_Api,
  Get_All_Plan_Api,
  Get_Single_Merchant_Data_Api,
  LogOutAuthForToeknInvalid,
  MerchantGateWay_Api,
  Plan_Api,
} from "../Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const SubscriptionPlan = () => {
  const [merchantData, setMerchantData] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(); // Default selected plan
  const [SelectedPlanFullData, setSelectedPlanFullData] = useState({});
  const [authToken, setauthToken] = useState(localStorage.getItem("authToken"));
  const [id, setid] = useState(localStorage.getItem("id"));
  const [paymentGateway, setPaymentGateway] = useState("Razorpay");
  const [paymentGatewayCredencial, setPaymentGatewayCredencial] = useState({
    key: "rzp_test_G2jrPcOvzZAwq1", // Default key for Razorpay
    secretKey: "asfcawer fdfsd111", // Default secret key for Razorpay
  });
  const [allpaymentGatewayData, setallpaymentGatewayData] = useState([
    {
      _id: "6720fd98660604a6611eb70b",
      name: "Razorpay",
      sortOrder: 1,
      key: "rzp_test_G2jrPcOvzZAwq1",
      secretKey: "asfcawer fdfsd111",
      live: true,
      createdAt: "2024-10-10T13:31:26.143Z",
      updatedAt: "2024-12-03T18:53:44.226Z",
      __v: 0,
      id: "6720fd98660604a6611eb70b",
    },
    {
      _id: "6720fd98660604a6611eb70b",
      name: "Paypal",
      sortOrder: 1,
      key: "AWiWzGyAFhyt3b6DeEUuOYomLsvKPFXeSJMrGn-wCGU0mRD8qlx8wiCuY1yAxXrnMipVTYw4fZzi4hzi",
      secretKey: "asfcawer fdfsd111",
      live: true,
      createdAt: "2024-10-10T13:31:26.143Z",
      updatedAt: "2024-12-03T18:53:44.226Z",
      __v: 0,
      id: "6720fd98660604a6611eb70b",
    },
  ]);
  const [showPayPalButton, setShowPayPalButton] = useState(false); // State to control PayPal button visibility

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: Get_All_Plan_Api,
    };

    axios
      .request(config)
      .then((response) => {
        setPlans(response.data.data);
        // Set the first plan as the default selected plan
        if (response.data.data.length > 0) {
          setSelectedPlan(response.data.data[0].credits);
          setSelectedPlanFullData(response.data.data[0]);
        }
      })
      .catch((error) => {
        // Handle error
      });
    getMerchantData();
    PaymentGetwayData();
  }, []);

  const getMerchantData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Get_Single_Merchant_Data_Api}${id}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const result = response.data.data;
        setMerchantData(result);
      })
      .catch((error) => {
        if (
          error.response?.data?.status === 401 ||
          error.response?.data?.status === 403
        ) {
          // LogOutAuthForToeknInvalid();
        }
      });
  };
  const PaymentGetwayData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${MerchantGateWay_Api}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const result = response.data.data;
        console.log(response.data.data.tableData.paymentGateways);
        if (response.data.data.tableData.paymentGateways) {
          setallpaymentGatewayData(
            response.data.data.tableData.paymentGateways
          );
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.status === 401 ||
          error.response?.data?.status === 403
        ) {
          // LogOutAuthForToeknInvalid();
        }
      });
  };

  const handleSelect = (selectedPlan) => {
    setSelectedPlan(selectedPlan.credits);
    setSelectedPlanFullData(selectedPlan);
    // Set the payment gateway credentials based on the selected plan
    const selectedGateway = allpaymentGatewayData.find(
      (item) => item.name === paymentGateway
    );
    setPaymentGatewayCredencial(selectedGateway); // Update payment credentials
  };

  const HandlePaynow = () => {
    if (paymentGateway === "Razorpay") {
      console.log(paymentGatewayCredencial);
      const options = {
        amount: SelectedPlanFullData.amount * 100,
        currency: "INR",
        key: paymentGatewayCredencial.key,
        name: "BlueRibbon Solution",
        description: "Payment for your order",
        image:
          "https://main.d1oepb357wdkqy.amplifyapp.com/assets/img/SuperAdminLogo.png",
        handler: function (response) {
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            ...SelectedPlanFullData,
          };
          const config = {
            method: "post",
            maxBodyLength: Infinity,
            url: Add_Payment_Api,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            data: JSON.stringify({
              name: SelectedPlanFullData?.name,
              amount: SelectedPlanFullData?.amount,
              credits: SelectedPlanFullData?.credits,
              plan: SelectedPlanFullData?.id,
              user: id,
              paymentMethod: "online",
            }),
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.status) {
                toast.success(response.data.message);
                document
                  .getElementsByClassName("add_payment_modal_Close")[0]
                  .click();
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              if (
                error.response?.status === 401 ||
                error.response?.status === 403
              ) {
                // LogOutAuthForToeknInvalid();
              }
            });
        },
        modal: {
          ondismiss: function () {
            // Handle modal dismissal if needed
          },
        },
        prefill: {
          name: merchantData?.name,
          email: merchantData?.email,
          contact: merchantData?.mobileNumber,
        },
        notes: {
          address: "Customer Address",
        },
        theme: {
          color: "#F37254",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      setShowPayPalButton(false); // Hide PayPal button if Razorpay is selected
    } else if (paymentGateway == "Paypal") {
      setShowPayPalButton(true); // Set to true to show PayPal button in the modal
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row min-vh-100">
          <Sidebar Active={"Plans"} />
          <main
            className="col py-3 ms-auto custom-container-align"
          // style={{ height: "100vh" }}  
          >
            <div className="container h-100" style={{ paddingLeft: '16px' }}>
              <div className="content-box-wrapper">
                <div className="custom-heading">
                  <h2>
                    <span className="pe-1">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M27.4939 2.36576C28.0251 2.43284 28.5182 2.62046 28.9482 3.0504C29.378 3.48035 29.5656 3.97338 29.6327 4.50466C29.6895 4.95372 29.6602 5.54878 29.6335 6.09119C29.4743 9.34576 28.4924 12.5325 26.0352 14.9898L24.0544 16.9706C23.19 17.835 22.8091 18.2323 22.6211 18.5995C22.4907 18.8543 22.4268 19.1498 22.5718 19.9122C22.7307 20.5474 22.9299 21.3431 22.9312 22.0858C22.9328 22.9039 22.706 23.7563 21.9842 24.4782C21.2955 25.1669 20.4858 25.6651 19.5412 25.6651C18.5968 25.6651 17.787 25.1669 17.0983 24.4782L7.52037 14.9002C6.83167 14.2115 6.33339 13.4017 6.33337 12.4573C6.33336 11.5128 6.83164 10.703 7.52037 10.0143C8.24221 9.29244 9.09453 9.06571 9.91268 9.06726C10.6554 9.06867 11.4511 9.26783 12.0864 9.4268C12.8487 9.5717 13.1442 9.50787 13.399 9.37743C13.7662 9.18935 14.1635 8.80845 15.028 7.94403L17.0087 5.96326C19.4659 3.50603 22.6527 2.5242 25.9074 2.36499C26.4498 2.33832 27.0448 2.30907 27.4939 2.36576Z"
                          fill="#9371F0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.3281 9.33333C21.3281 8.59696 21.9251 8 22.6615 8H22.6735C23.4099 8 24.0068 8.59696 24.0068 9.33333C24.0068 10.0697 23.4099 10.6667 22.6735 10.6667H22.6615C21.9251 10.6667 21.3281 10.0697 21.3281 9.33333Z"
                          fill="#9371F0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.02904 17.3683C7.52371 17.863 7.52371 18.665 7.02904 19.1596L4.49571 21.693C4.00104 22.1876 3.19904 22.1876 2.70437 21.693C2.20971 21.1983 2.20971 20.3963 2.70437 19.9016L5.23771 17.3683C5.73237 16.8736 6.53437 16.8736 7.02904 17.3683ZM9.03771 21.1683C9.53237 20.6736 10.3344 20.6736 10.829 21.1683C11.3237 21.663 11.3237 22.465 10.829 22.9596L4.49571 29.293C4.00104 29.7876 3.19904 29.7876 2.70437 29.293C2.20971 28.7983 2.20971 27.9963 2.70437 27.5016L9.03771 21.1683ZM14.6291 24.9683C15.1236 25.463 15.1236 26.265 14.6291 26.7596L12.0957 29.293C11.601 29.7876 10.799 29.7876 10.3044 29.293C9.80971 28.7983 9.80971 27.9963 10.3044 27.5016L12.8377 24.9683C13.3324 24.4736 14.1344 24.4736 14.6291 24.9683Z"
                          fill="#9371F0"
                        />
                      </svg>
                    </span>
                    Subscription Plan
                  </h2>
                </div>
              </div>
              <div className="col-12 text-center pt-3">
                <img src="/assets/img/Design.png" alt="pricing" />
                <h1 className="contact-us-heading mt-4">Our Pricing</h1>
                <p className="opacity-50 contact-us-small-heading" style={{ lineHeight: '28px', fontWeight: '500' }}>
                  Save hundreds compared to a photo shoot. <br /> Customize your AI
                  professional headshot with manual edits or get.
                </p>
              </div>
              <div className="d-flex justify-content-center col-12">
                <div className="col-12 col-lg-6 px-16 mb-4 mb-lg-0" style={{ paddingRight: '8px' }}>
                  <div className="custom-card-design h-100">
                    <h4>Subscription Plans</h4>
                    <img
                      src="/assets/img/Divider.png"
                      alt="Divider"
                      className="w-100"
                    />
                    <ul className="list-unstyled mt-3">
                      {plans.map((item, index) => {
                        return (
                          <li
                            className="d-flex justify-content-between align-items-center py-1"
                            key={index}
                          >
                            <div className="col-4 text-start ps-3">
                              <input
                                type="radio"
                                name="plan"
                                className="me-2"
                                checked={selectedPlan === item.credits}
                                onChange={() => handleSelect(item)}
                              />
                              <p
                                style={{ fontWeight: "600" }}
                                className="d-inline-block m-0"
                              >
                                {item.credits} Credits
                              </p>
                            </div>
                            <div
                              className="col-3 text-center small-custom-text small-custom-text-urbanist"
                            >
                              ₹0.07 / image
                            </div>
                            <div
                              className="col-3 text-end pe-3"
                              style={{ fontSize: "20px" }}
                            >
                              ₹<b>{item.amount}</b>.00
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <button
                      className="contact-us-custom-btn text-center mt-3 d-block mx-auto"
                      style={{ borderRadius: "10px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#PaymentModal"
                    >
                      Subscribe Now
                      <span className="ps-2">
                        <svg
                          width="23"
                          height="22"
                          viewBox="0 0 23 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M14.5 0.25C14.8507 0.25 15.1546 0.49305 15.2317 0.83518L15.7704 3.22676C16.1637 4.97278 17.5272 6.33629 19.2732 6.7296L21.6648 7.26833C22.007 7.3454 22.25 7.64929 22.25 8C22.25 8.35071 22.007 8.6546 21.6648 8.73167L19.2732 9.2704C17.5272 9.6637 16.1637 11.0272 15.7704 12.7732L15.2317 15.1648C15.1546 15.507 14.8507 15.75 14.5 15.75C14.1493 15.75 13.8454 15.507 13.7683 15.1648L13.2296 12.7732C12.8363 11.0272 11.4728 9.6637 9.7268 9.2704L7.33518 8.73167C6.99305 8.6546 6.75 8.35071 6.75 8C6.75 7.64929 6.99305 7.3454 7.33518 7.26833L9.7268 6.7296C11.4728 6.33629 12.8363 4.97278 13.2296 3.22676L13.7683 0.83518C13.8454 0.49305 14.1493 0.25 14.5 0.25Z"
                            fill="white"
                          />
                          <path
                            d="M6.5 10.25C6.85071 10.25 7.1546 10.493 7.23167 10.8352L7.61647 12.5435C7.87923 13.7099 8.79012 14.6208 9.9565 14.8835L11.6648 15.2683C12.007 15.3454 12.25 15.6493 12.25 16C12.25 16.3507 12.007 16.6546 11.6648 16.7317L9.9565 17.1165C8.79012 17.3792 7.87923 18.2901 7.61647 19.4565L7.23167 21.1648C7.1546 21.507 6.85071 21.75 6.5 21.75C6.14929 21.75 5.8454 21.507 5.76833 21.1648L5.38353 19.4565C5.12077 18.2901 4.20988 17.3792 3.04345 17.1165L1.33518 16.7317C0.99305 16.6546 0.75 16.3507 0.75 16C0.75 15.6493 0.99305 15.3454 1.33518 15.2683L3.04345 14.8835C4.20988 14.6208 5.12077 13.7099 5.38353 12.5435L5.76833 10.8352C5.8454 10.493 6.14929 10.25 6.5 10.25Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-12 col-lg-6 px-16 " style={{ paddingLeft: '8px' }}>
                  <div className="custom-card-design h-100">
                    <h4>Enterprise Plan</h4>
                    <img
                      src="/assets/img/Divider.png"
                      alt="Divider"
                      className="w-100"
                    />
                    <ul className="list-unstyled mt-3">
                      <li className="d-flex justify-content-between align-items-center py-1">
                        <p
                          style={{ fontWeight: "600" }}
                          className="d-inline-block m-0"
                        >
                          <span className="pe-2">
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                                stroke="#9371F0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Best price guaranteed
                        </p>
                      </li>
                      <li className="d-flex justify-content-between align-items-center py-1">
                        <p
                          style={{ fontWeight: "600" }}
                          className="d-inline-block m-0"
                        >
                          <span className="pe-2">
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                                stroke="#9371F0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Flexible API
                        </p>
                      </li>
                      <li className="d-flex justify-content-between align-items-center py-1">
                        <p
                          style={{ fontWeight: "600" }}
                          className="d-inline-block m-0"
                        >
                          <span className="pe-2">
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                                stroke="#9371F0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Dedicated Support
                        </p>
                      </li>
                      <li className="d-flex justify-content-between align-items-center py-1">
                        <p
                          style={{ fontWeight: "600" }}
                          className="d-inline-block m-0"
                        >
                          <span className="pe-2">
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                                stroke="#9371F0"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Custom Solutions
                        </p>
                      </li>
                    </ul>
                    <button
                      className="subscribe-custom-btn mt-3"
                      onClick={() => {
                        window.location.href = "/Merchant/ContactUs";
                      }}
                      style={{ borderRadius: "10px" }}
                    >
                      Contact Us
                      <span className="ps-2">
                        <svg
                          width="18"
                          height="12"
                          viewBox="0 0 18 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 6H1"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.0001 11C12.0001 11 17 7.3176 17 6C17 4.6824 12 1 12 1"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center align-items-center h-100">
                <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                  <div className="custom-card-design">
                    <h4 className="text-center">Subscription Plans</h4>
                    <img
                      src="/assets/img/Divider.png"
                      alt="Divider"
                      className="w-100"
                    />
                    <ul className="list-unstyled mt-3">
                      {plans.map((item, index) => {
                        return (
                          <li
                            className="d-flex justify-content-between align-items-center py-1"
                            key={index}
                          >
                            <div className="col-4 text-start ps-3">
                              <input
                                type="radio"
                                name="plan"
                                className="me-2"
                                checked={selectedPlan === item.credits}
                                onChange={() => handleSelect(item)}
                              />
                              <p
                                style={{ fontWeight: "600" }}
                                className="d-inline-block m-0"
                              >
                                {item.credits} Credits
                              </p>
                            </div>
                            <div
                              className="col-4 text-end pe-3"
                              style={{ fontSize: "20px" }}
                            >
                              ₹<b>{item.amount}</b>.00
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <button
                      className="subscribe-custom-btn text-center mt-3"
                      style={{ borderRadius: "10px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#PaymentModal"
                    >
                      Subscribe Now
                      <span className="ps-2">
                        <svg
                          width="23"
                          height="22"
                          viewBox="0 0 23 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M14.5 0.25C14.8507 0.25 15.1546 0.49305 15.2317 0.83518L15.7704 3.22676C16.1637 4.97278 17.5272 6.33629 19.2732 6.7296L21.6648 7.26833C22.007 7.3454 22.25 7.64929 22.25 8C22.25 8.35071 22.007 8.6546 21.6648 8.73167L19.2732 9.2704C17.5272 9.6637 16.1637 11.0272 15.7704 12.7732L15.2317 15.1648C15.1546 15.507 14.8507 15.75 14.5 15.75C14.1493 15.75 13.8454 15.507 13.7683 15.1648L13.2296 12.7732C12.8363 11.0272 11.4728 9.6637 9.7268 9.2704L7.33518 8.73167C6.99305 8.6546 6.75 8.35071 6.75 8C6.75 7.64929 6.99305 7.3454 7.33518 7.26833L9.7268 6.7296C11.4728 6.33629 12.8363 4.97278 13.2296 3.22676L13.7683 0.83518C13.8454 0.49305 14.1493 0.25 14.5 0.25Z"
                            fill="white"
                          />
                          <path
                            d="M6.5 10.25C6.85071 10.25 7.1546 10.493 7.23167 10.8352L7.61647 12.5435C7.87923 13.7099 8.79012 14.6208 9.9565 14.8835L11.6648 15.2683C12.007 15.3454 12.25 15.6493 12.25 16C12.25 16.3507 12.007 16.6546 11.6648 16.7317L9.9565 17.1165C8.79012 17.3792 7.87923 18.2901 7.61647 19.4565L7.23167 21.1648C7.1546 21.507 6.85071 21.75 6.5 21.75C6.14929 21.75 5.8454 21.507 5.76833 21.1648L5.38353 19.4565C5.12077 18.2901 4.20988 17.3792 3.04345 17.1165L1.33518 16.7317C0.99305 16.6546 0.75 16.3507 0.75 16C0.75 15.6493 0.99305 15.3454 1.33518 15.2683L3.04345 14.8835C4.20988 14.6208 5.12077 13.7099 5.38353 12.5435L5.76833 10.8352C5.8454 10.493 6.14929 10.25 6.5 10.25Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div> */}
              {/* <div className="content-box-wrapper mt-3"> */}
              {/* <div className="mx-auto price-plan-main">
                  <ul className="list-group list-group-flush">
                    {plans.map((plan, index) => (
                      <li key={index} className="list-group-item border-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <label className="form-check-label float-start">
                              <input
                                type="radio"
                                name="plan"
                                className="form-check-input me-2"
                                checked={selectedPlan === plan.credits}
                                onChange={() => handleSelect(plan)}
                              />
                              <b>{`${plan.credits} Credits`}</b>
                            </label>
                          </div>
                          <div
                            className="text-muted text-center"
                            style={{ fontWeight: "500" }}
                          >
                            {plan.price}
                          </div>
                          <div className="text-end plan-price">
                            $ <b>{plan.amount}</b>.00
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <button
                    className="stylish-btn mx-auto mt-2"
                    data-bs-toggle="modal"
                    data-bs-target="#PaymentModal"
                  >
                    Subscribe Now
                    <span className="ps-2">
                      <svg
                        width="29"
                        height="30"
                        viewBox="0 0 29 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_i_54_32)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.5001 0.666748C6.584 0.666748 0.166748 7.084 0.166748 15.0001C0.166748 22.9162 6.584 29.3334 14.5001 29.3334C22.4162 29.3334 28.8334 22.9162 28.8334 15.0001C28.8334 7.084 22.4162 0.666748 14.5001 0.666748ZM16.8691 7.81379C16.8691 7.08085 16.4735 6.42721 15.8346 6.14129C15.1585 5.83868 14.3551 6.02409 13.8674 6.68148L8.53537 13.8678C8.11012 14.441 8.08632 15.1455 8.29415 15.6907C8.5004 16.2319 9.02952 16.8122 9.85243 16.8122H12.1305V22.1865C12.1305 22.9194 12.5261 23.573 13.1649 23.859C13.841 24.1615 14.6445 23.9762 15.1322 23.3187L20.4642 16.1325C20.8894 15.5593 20.9133 14.8547 20.7054 14.3094C20.4991 13.7683 19.9701 13.1881 19.1471 13.1881H16.8691V7.81379Z"
                            fill="#7F58EC"
                          />
                        </g>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.8691 7.81371C16.8691 7.08077 16.4735 6.42713 15.8346 6.14121C15.1585 5.8386 14.3551 6.02402 13.8674 6.6814L8.53537 13.8677C8.11012 14.4409 8.08632 15.1455 8.29414 15.6907C8.5004 16.2319 9.02952 16.8121 9.85242 16.8121H12.1305V22.1864C12.1305 22.9193 12.5261 23.5729 13.1649 23.8589C13.841 24.1615 14.6445 23.9761 15.1322 23.3187L20.4642 16.1324C20.8894 15.5592 20.9133 14.8547 20.7054 14.3093C20.4991 13.7683 19.9701 13.188 19.1471 13.188H16.8691V7.81371Z"
                          fill="white"
                        />
                        <defs>
                          <filter
                            id="filter0_i_54_32"
                            x="0.166748"
                            y="0.666748"
                            width="28.6667"
                            height="32.6667"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect1_innerShadow_54_32"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </span>
                  </button>
                </div> */}
              {/* </div> */}

              <div
                className="modal fade"
                id="PaymentModal"
                tabIndex="-1"
                aria-labelledby="PaymentModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <form className="w-100">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="custom-btn-light-with-box-shadow me-3"
                          data-bs-dismiss="modal"
                        >
                          <span>
                            <svg
                              width="16"
                              height="10"
                              viewBox="0 0 16 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.3335 5H14.6668"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.50013 9.16659C5.50013 9.16659 1.3335 6.09792 1.3335 4.99992C1.33349 3.90192 5.50016 0.833252 5.50016 0.833252"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </button>
                        <h5
                          className="modal-title m-0 d-flex align-items-center"
                          id="AddPaymentLabel"
                        >
                          Payment
                        </h5>
                      </div>
                      <div className="modal-body">
                        <div className="form-wrapper p-0 shadow-sm overflow-hidden">
                          <table className="table table-borderless custom-table mb-0">
                            <tbody>
                              <tr>
                                <td className="ps-3">
                                  <div className="opacity-50">
                                    Payment Gateway
                                  </div>
                                </td>
                                <td>
                                  <select
                                    className="w-100 makeInputStyleNone"
                                    name="paymentGateway"
                                    id="paymentGateway"
                                    value={paymentGateway}
                                    onChange={(e) => {
                                      const selectedGateway =
                                        allpaymentGatewayData.find(
                                          (item) => item.name === e.target.value
                                        );
                                      setPaymentGateway(e.target.value);
                                      setPaymentGatewayCredencial(
                                        selectedGateway
                                      ); // Set the full object of the selected payment method
                                      // Hide PayPal button if Razorpay is selected
                                      if (e.target.value === "Razorpay") {
                                        setShowPayPalButton(false);
                                      }
                                    }}
                                    required
                                  >
                                    {allpaymentGatewayData.map((item, key) => {
                                      return item.live ? (
                                        <option key={key} value={item.name}>
                                          {item.name}
                                        </option>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  </select>
                                </td>
                              </tr>
                              {showPayPalButton && (
                                <tr>
                                  <td colSpan="2">
                                    <PayPalScriptProvider
                                      options={{
                                        "client-id":
                                          paymentGatewayCredencial?.key ||
                                          "AWiWzGyAFhyt3b6DeEUuOYomLsvKPFXeSJMrGn-wCGU0mRD8qlx8wiCuY1yAxXrnMipVTYw4fZzi4hzi",
                                      }}
                                    >
                                      <PayPalButtons
                                        createOrder={(data, actions) => {
                                          return actions.order.create({
                                            purchase_units: [
                                              {
                                                amount: {
                                                  value:
                                                    SelectedPlanFullData?.amount ||
                                                    "0.00",
                                                },
                                              },
                                            ],
                                          });
                                        }}
                                        onApprove={(data, actions) => {
                                          return actions.order
                                            .capture()
                                            .then((details) => {
                                              const selectedPlan =
                                                SelectedPlanFullData;
                                              const send_data = {
                                                name: SelectedPlanFullData.name,
                                                amount:
                                                  SelectedPlanFullData.amount,
                                                credits:
                                                  SelectedPlanFullData.credits,
                                              };

                                              const config = {
                                                method: "post",
                                                maxBodyLength: Infinity,
                                                url: Add_Payment_Api,
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${authToken}`,
                                                },
                                                data: JSON.stringify({
                                                  name: SelectedPlanFullData?.name,
                                                  amount:
                                                    SelectedPlanFullData?.amount,
                                                  credits:
                                                    SelectedPlanFullData?.credits,
                                                  plan: SelectedPlanFullData?.id,
                                                  user: id,
                                                  paymentMethod: "online",
                                                }),
                                              };

                                              axios
                                                .request(config)
                                                .then((response) => {
                                                  if (response.data.status) {
                                                    toast.success(
                                                      response.data.message
                                                    );
                                                    document
                                                      .getElementsByClassName(
                                                        "add_payment_modal_Close"
                                                      )[0]
                                                      .click();
                                                  } else {
                                                    toast.error(
                                                      response.data.message
                                                    );
                                                  }
                                                })
                                                .catch((error) => {
                                                  console.error(
                                                    "API Fetch Error:",
                                                    error
                                                  );
                                                  if (
                                                    error.response?.status ===
                                                    401 ||
                                                    error.response?.status ===
                                                    403
                                                  ) {
                                                    // LogOutAuthForToeknInvalid();
                                                  }
                                                });
                                            });
                                        }}
                                        onError={(err) => {
                                          console.error(
                                            "PayPal Checkout Error:",
                                            err
                                          );
                                        }}
                                      />
                                    </PayPalScriptProvider>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="modal-footer d-flex">
                        <button
                          type="button"
                          className="simple-light-btn add_payment_modal_Close"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="simple-dark-btn col"
                          onClick={() => {
                            HandlePaynow();
                          }}
                        >
                          Pay Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;

// <option value="razorpay">Demo Razorpay</option>
// <option value="paypal">PayPal</option>
