import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { React, useEffect, useMemo, useState } from 'react';
import Sidebar from '../UI/Merchant/SideNav';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

//Material UI Imports
import {
    Box
} from '@mui/material';
import { Get_User_Payment_Api, LogOutAuthForToeknInvalid } from '../Api';
import axios from 'axios';
import dayjs from 'dayjs';

const PaymentList = () => {
    const [authToken, setauthToken] = useState(localStorage.getItem('authToken'));
    const [data, setdata] = useState([]);
    let id = localStorage.getItem('id');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [sortOrderAmount, setSortOrderAmount] = useState('normal');
    const [sortOrderCredits, setSortOrderCredits] = useState('normal');

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const filteredTableData = data.filter((item) =>
        Object.values(item).some((val) =>
            val ? val.toString().toLowerCase().includes(filterText.toLowerCase()) : false
        )
    );

    const sortedData = useMemo(() => {
        let sorted = [...filteredTableData];
        if (sortOrderAmount === 'ascending') {
            sorted.sort((a, b) => (a.amount_spend || 0) - (b.amount_spend || 0));
        } else if (sortOrderAmount === 'descending') {
            sorted.sort((a, b) => (b.amount_spend || 0) - (a.amount_spend || 0));
        }
        return sorted;
    }, [filteredTableData, sortOrderAmount]);

    const sortedDataWithCredits = useMemo(() => {
        let sorted = [...sortedData];
        if (sortOrderCredits === 'ascending') {
            sorted.sort((a, b) => (a.successCredits || 0) - (b.successCredits || 0));
        } else if (sortOrderCredits === 'descending') {
            sorted.sort((a, b) => (b.successCredits || 0) - (a.successCredits || 0));
        }
        return sorted;
    }, [sortedData, sortOrderCredits]);

    const currentRows = sortedDataWithCredits.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(sortedDataWithCredits.length / rowsPerPage);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return; // Prevent invalid page change
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page on rows per page change
    };

    const handleSortAmount = () => {
        setSortOrderAmount((prevOrder) => {
            if (prevOrder === 'normal') return 'ascending';
            if (prevOrder === 'ascending') return 'descending';
            return 'normal';
        });
    };

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Get_User_Payment_Api + id,
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        };
        axios.request(config)
            .then((response) => {
                let result = response.data.data.payments;
                let FinalData = [];
                for (const obj of result) {
                    obj.plan = obj.plan?.name;
                    FinalData.push(obj)
                }
                setdata(FinalData);
            })
            .catch((error) => {
                if (error.response.data.status == 401 || error.response.data.status == 403) {
                    LogOutAuthForToeknInvalid();
                }
            });
    }, []);

    const handleDownload = (row) => {
        const json = JSON.stringify(row);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${row.invoice}.json`;
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row min-vh-100">
                    <Sidebar Active={'Bill & Payments'} />
                    <main className="col py-3 ms-auto custom-container-align">
                        <div className="container">
                            <div className="content-box-wrapper" style={{ padding: '5px' }}>
                                <div className='table-srollablle full-page-table'>
                                    <table className="table" style={{ borderCollapse: 'separate', borderSpacing: 0, width: '100%' }}>
                                        <thead className="custom-thead" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <tr>
                                                <th style={{ width: '100px' }}>
                                                    <div className='table-heading'> # </div>
                                                </th>
                                                <th>
                                                    <div className='table-heading'>Date & Time </div>
                                                </th>
                                                <th>
                                                    <div className='table-heading'>Invoice No </div>
                                                </th>
                                                <th onClick={handleSortAmount} style={{ cursor: 'pointer' }}>
                                                    <div className='table-heading'>Amount</div>
                                                </th>
                                                <th>
                                                    <div className='table-heading'>Plan Name </div>
                                                </th>
                                                <th style={{ width: '100px' }}>
                                                    <div className='table-heading'>Action </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='custom-tbody'>
                                            {currentRows.length > 0 ? (
                                                currentRows.map((row, index) => (
                                                    <tr key={row.id}>
                                                        <td style={{ width: '80px' }} className='text-center'>{index + 1 + (currentPage - 1) * rowsPerPage}</td>
                                                        <td className='ps-20px text-center'>
                                                            {row?.createdAt &&
                                                                new Date(row.createdAt).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: false,
                                                                    }
                                                                )}
                                                        </td>
                                                        <td className='ps-20px text-center'>
                                                            {row.invoiceNo}
                                                        </td>
                                                        <td className='text-center'>
                                                            {row.amount}
                                                        </td>
                                                        <td className='text-center'>
                                                            {row.plan}
                                                        </td>
                                                        <td className='text-center'>
                                                            <button className='download-btn' onClick={() => handleDownload(row)}>
                                                                <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.0001 12.5V4.16663M10.0001 12.5C9.41658 12.5 8.32636 10.838 7.91675 10.4166M10.0001 12.5C10.5836 12.5 11.6738 10.838 12.0834 10.4166" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M4.16675 15.8334H15.8335" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">
                                                        No data found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between align-items-center table-pagination-box" style={{ position: 'sticky', bottom: 0, zIndex: 1 }}>
                                        <ul className="custom-pagination p-0">
                                            <li>
                                                Page {currentPage} of {totalPages}
                                            </li>
                                            <select
                                                className="light-custom-box"
                                                value={rowsPerPage}
                                                onChange={handleRowsPerPageChange}
                                            >
                                                <option value="10">10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                            </select>
                                        </ul>
                                        <nav>
                                            <ul className="custom-pagination">
                                                <li className="page-item" onClick={() => handlePageChange(currentPage - 1)}>
                                                    <button
                                                        className="page-link"
                                                        disabled={currentPage === 1}
                                                    >
                                                        Previous
                                                    </button>
                                                </li>
                                                {[...Array(totalPages).keys()].map((page) => (
                                                    <li
                                                        key={page + 1}
                                                        onClick={() => handlePageChange(page + 1)}
                                                        className={`page-item ${page + 1 === currentPage ? "active" : ""}`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                        >
                                                            {page + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                                <li className="page-item" onClick={() => handlePageChange(currentPage + 1)}>
                                                    <button
                                                        className="page-link"
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default PaymentList;
