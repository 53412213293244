import axios from "axios";
import React, { useEffect, useState } from "react";
import { Get_Current_User_Api, LogOutAuthForToeknInvalid } from "../../Api";

const Sidebar = (props) => {
  const [authToken, setauthToken] = useState(localStorage.getItem("authToken"));
  const [data, setdata] = useState({});
  let Active = props.Active;
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: Get_Current_User_Api,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setdata(response.data.data);
      })
      .catch((error) => {
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          LogOutAuthForToeknInvalid();
        }
      });
  }, []);
  return (
    <>
      <aside className="col-md-4 col-lg-3 pe-0" style={{ position: "fixed" }}>
        <div className="vh-100 py-3">
          <div
            className="sidebar custom-light-gray h-100 d-flex justify-content-between"
            style={{ flexDirection: "column", gap: "0" }}
          >
            <div>
              <div className="text-center p-3 pb-0">
                <img
                  src="/assets/img/userAdminLogo.png"
                  alt="ApiAdmin Logo"
                  className="img-fluid mb-2"
                  style={{ width: "200px" }}
                />
              </div>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <a
                    className={`nav-link ${Active == "Dashboard" ? "active" : ""
                      }`}
                    href="/Merchant/Dashboard"
                  >
                    <span>
                      {Active == "Dashboard" ? <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_i_2139_1090)">
                          <path d="M14.0763 0.333344H13.9238C11.0029 0.33333 8.70863 0.333317 6.9174 0.57413C5.08187 0.820917 3.62552 1.33697 2.48125 2.48122C1.337 3.62549 0.820948 5.08184 0.574161 6.91737C0.333348 8.7086 0.333361 11.0028 0.333374 13.9237V14.0763C0.333361 16.9972 0.333348 19.2915 0.574161 21.0827C0.820948 22.9181 1.337 24.3745 2.48125 25.5188C3.62552 26.6631 5.08187 27.1791 6.9174 27.4259C8.70863 27.6667 11.0028 27.6667 13.9238 27.6667H14.0763C16.9972 27.6667 19.2915 27.6667 21.0827 27.4259C22.9182 27.1791 24.3746 26.6631 25.5188 25.5188C26.6631 24.3745 27.1791 22.9181 27.4259 21.0827C27.6667 19.2915 27.6667 16.9972 27.6667 14.0763V13.9237C27.6667 11.0029 27.6667 8.7086 27.4259 6.91737C27.1791 5.08184 26.6631 3.62549 25.5188 2.48122C24.3746 1.33697 22.9182 0.820917 21.0827 0.57413C19.2915 0.333317 16.9972 0.33333 14.0763 0.333344Z" fill="#7F58EC" />
                        </g>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4284 7.76621L14.5352 9.06396C12.5216 9.73516 11.5148 10.0708 10.7928 10.7928C10.0708 11.5148 9.73519 12.5216 9.06399 14.5352L7.76624 18.4284C7.37236 19.61 7.17542 20.2009 7.4873 20.5128C7.79918 20.8247 8.39 20.6277 9.57166 20.2337L13.4648 18.936C15.4784 18.2648 16.4852 17.9292 17.2072 17.2072C17.9292 16.4852 18.2648 15.4784 18.936 13.4648L20.2339 9.57161C20.6278 8.38996 20.8247 7.79915 20.5128 7.48727C20.201 7.17539 19.61 7.37233 18.4284 7.76621ZM14.944 14.9356C15.4631 14.4165 15.4631 13.5751 14.944 13.056C14.425 12.5369 13.5835 12.5369 13.0644 13.056L13.056 13.0644C12.537 13.5835 12.537 14.4249 13.056 14.944C13.5751 15.4631 14.4166 15.4631 14.9356 14.944L14.944 14.9356Z" fill="white" />
                        <defs>
                          <filter id="filter0_i_2139_1090" x="0.333374" y="0.333344" width="27.3334" height="31.3333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2139_1090" />
                          </filter>
                        </defs>
                      </svg>
                        :
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_i_54_32)">
                            <path
                              d="M14.0763 0.333344H13.9238C11.0029 0.33333 8.70863 0.333317 6.9174 0.57413C5.08187 0.820917 3.62552 1.33697 2.48125 2.48122C1.337 3.62549 0.820948 5.08184 0.574161 6.91737C0.333348 8.7086 0.333361 11.0028 0.333374 13.9237V14.0763C0.333361 16.9972 0.333348 19.2915 0.574161 21.0827C0.820948 22.9181 1.337 24.3745 2.48125 25.5188C3.62552 26.6631 5.08187 27.1791 6.9174 27.4259C8.70863 27.6667 11.0028 27.6667 13.9238 27.6667H14.0763C16.9972 27.6667 19.2915 27.6667 21.0827 27.4259C22.9182 27.1791 24.3746 26.6631 25.5188 25.5188C26.6631 24.3745 27.1791 22.9181 27.4259 21.0827C27.6667 19.2915 27.6667 16.9972 27.6667 14.0763V13.9237C27.6667 11.0029 27.6667 8.7086 27.4259 6.91737C27.1791 5.08184 26.6631 3.62549 25.5188 2.48122C24.3746 1.33697 22.9182 0.820917 21.0827 0.57413C19.2915 0.333317 16.9972 0.33333 14.0763 0.333344Z"
                              fill="#7F58EC"
                            />
                          </g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.4284 7.76621L14.5352 9.06396C12.5216 9.73516 11.5148 10.0708 10.7928 10.7928C10.0708 11.5148 9.73519 12.5216 9.06399 14.5352L7.76624 18.4284C7.37236 19.61 7.17542 20.2009 7.4873 20.5128C7.79918 20.8247 8.39 20.6277 9.57166 20.2337L13.4648 18.936C15.4784 18.2648 16.4852 17.9292 17.2072 17.2072C17.9292 16.4852 18.2648 15.4784 18.936 13.4648L20.2339 9.57161C20.6278 8.38996 20.8247 7.79915 20.5128 7.48727C20.201 7.17539 19.61 7.37233 18.4284 7.76621ZM14.944 14.9356C15.4631 14.4165 15.4631 13.5751 14.944 13.056C14.425 12.5369 13.5835 12.5369 13.0644 13.056L13.056 13.0644C12.537 13.5835 12.537 14.4249 13.056 14.944C13.5751 15.4631 14.4166 15.4631 14.9356 14.944L14.944 14.9356Z"
                            fill="white"
                          />
                          <defs>
                            <filter
                              id="filter0_i_54_32"
                              x="0.333374"
                              y="0.333344"
                              width="27.3334"
                              height="31.3333"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_54_32"
                              />
                            </filter>
                          </defs>
                        </svg>
                      }
                    </span>
                    {" "}
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Active == "Account Setting" ? "active" : ""
                      }`}
                    href="/Merchant/AccountSetting"
                  >
                    <span>
                      {Active == 'Account Setting' ? <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_i_2139_1091)">
                          <path d="M14.0763 0.333344C16.9972 0.33333 19.2915 0.333317 21.0827 0.57413C22.9182 0.820917 24.3746 1.33697 25.5188 2.48122C26.6631 3.62549 27.1791 5.08184 27.4259 6.91737C27.6667 8.7086 27.6667 11.0028 27.6667 13.9237V14.0763C27.6667 16.9972 27.6667 19.2915 27.4259 21.0827C27.1791 22.9181 26.6631 24.3745 25.5188 25.5188C24.3746 26.6631 22.9182 27.1791 21.0827 27.4259C19.2915 27.6667 16.9972 27.6667 14.0763 27.6667H13.9238C11.0028 27.6667 8.70863 27.6667 6.9174 27.4259C5.08187 27.1791 3.62552 26.6631 2.48125 25.5188C1.337 24.3745 0.820948 22.9181 0.574161 21.0827C0.333348 19.2915 0.333361 16.9972 0.333374 14.0763V13.9237C0.333361 11.0028 0.333348 8.7086 0.574161 6.91737C0.820948 5.08184 1.337 3.62549 2.48125 2.48122C3.62552 1.33697 5.08187 0.820917 6.9174 0.57413C8.70863 0.333317 11.0029 0.33333 13.9238 0.333344H14.0763Z" fill="#7F58EC" />
                        </g>
                        <path d="M9.65063 10.6667C9.65063 8.2721 11.5941 6.33334 13.9887 6.33334C16.3832 6.33334 18.3268 8.2721 18.3268 10.6667C18.3268 13.0612 16.3832 15 13.9887 15C11.5941 15 9.65063 13.0612 9.65063 10.6667Z" fill="white" />
                        <path d="M7.30944 21.39C6.90999 21.0085 6.89535 20.3756 7.27672 19.9761C10.7607 16.3271 17.1788 16.13 20.7353 19.9889C21.1096 20.3951 21.0839 21.0277 20.6777 21.402C20.4855 21.5792 20.2424 21.6667 20 21.6667H8C7.75177 21.6667 7.50315 21.5749 7.30944 21.39Z" fill="white" />
                        <defs>
                          <filter id="filter0_i_2139_1091" x="0.333374" y="0.333344" width="27.3334" height="31.3333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2139_1091" />
                          </filter>
                        </defs>
                      </svg>
                        :
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0763 0.333344C16.9972 0.33333 19.2915 0.333317 21.0827 0.57413C22.9182 0.820917 24.3746 1.33697 25.5188 2.48122C26.6631 3.62549 27.1791 5.08184 27.4259 6.91737C27.6667 8.7086 27.6667 11.0028 27.6667 13.9237V14.0763C27.6667 16.9972 27.6667 19.2915 27.4259 21.0827C27.1791 22.9181 26.6631 24.3745 25.5188 25.5188C24.3746 26.6631 22.9182 27.1791 21.0827 27.4259C19.2915 27.6667 16.9972 27.6667 14.0763 27.6667H13.9238C11.0028 27.6667 8.70863 27.6667 6.9174 27.4259C5.08187 27.1791 3.62552 26.6631 2.48125 25.5188C1.337 24.3745 0.820948 22.9181 0.574161 21.0827C0.333348 19.2915 0.333361 16.9972 0.333374 14.0763V13.9237C0.333361 11.0028 0.333348 8.7086 0.574161 6.91737C0.820948 5.08184 1.337 3.62549 2.48125 2.48122C3.62552 1.33697 5.08187 0.820917 6.9174 0.57413C8.70863 0.333317 11.0029 0.33333 13.9238 0.333344H14.0763Z"
                            fill="#9371F0"
                          />
                          <path
                            d="M9.65063 10.6667C9.65063 8.2721 11.5941 6.33334 13.9887 6.33334C16.3832 6.33334 18.3268 8.2721 18.3268 10.6667C18.3268 13.0612 16.3832 15 13.9887 15C11.5941 15 9.65063 13.0612 9.65063 10.6667Z"
                            fill="white"
                          />
                          <path
                            d="M7.30944 21.39C6.90999 21.0085 6.89535 20.3756 7.27672 19.9761C10.7607 16.3271 17.1788 16.13 20.7353 19.9889C21.1096 20.3951 21.0839 21.0277 20.6777 21.402C20.4855 21.5792 20.2424 21.6667 20 21.6667H8C7.75177 21.6667 7.50315 21.5749 7.30944 21.39Z"
                            fill="white"
                          />
                        </svg>}
                    </span>
                    {" "}
                    Account Setting
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Active == "Bill & Payments" ? "active" : ""
                      }`}
                    href="/Merchant/PaymentList"
                  >
                    <span>
                      {Active == "Bill & Payments" ? <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_i_2139_1092)">
                          <path d="M14.7404 0.666627H9.25964C7.4367 0.6666 5.9664 0.666573 4.80986 0.82348C3.60807 0.98652 2.59686 1.33544 1.79467 2.14488C0.993724 2.95308 0.64959 3.96976 0.48859 5.17812C0.333337 6.34333 0.33335 7.82535 0.333377 9.66627V23.9344C0.333323 24.8793 0.33327 25.693 0.41991 26.3158C0.508737 26.9544 0.723337 27.6809 1.40783 28.1218C2.41918 28.7732 3.54004 28.3222 4.1695 27.9929C4.52996 27.8044 5.54696 27.1153 5.82798 26.9222C6.19172 26.6928 6.39311 26.5706 6.54391 26.4998C6.57284 26.4688 6.66831 26.4274 6.81876 26.5102C6.97104 26.5865 7.16559 26.7078 7.50546 26.9222L10.089 28.5514C10.4018 28.7488 10.698 28.9357 10.9652 29.0676C11.2635 29.2148 11.601 29.3333 12 29.3333C12.3991 29.3333 12.7366 29.2148 13.0348 29.0676C13.302 28.9357 13.5982 28.7489 13.911 28.5516L16.4947 26.9222C16.8584 26.6928 17.0598 26.5706 17.2106 26.4998C17.244 26.4714 17.3458 26.4337 17.4854 26.5102C17.6378 26.5865 18.5106 27.1737 18.8504 27.3881C19.1314 27.5812 19.4702 27.8044 19.8306 27.9929C20.46 28.3222 21.581 28.7732 22.5923 28.1218C23.2767 27.6809 23.4914 26.9544 23.5802 26.3158C23.6668 25.693 23.6667 24.8793 23.6667 23.9344V9.66633C23.6667 7.82539 23.6667 6.34335 23.5115 5.17812C23.3504 3.96976 23.0063 2.95308 22.2054 2.14488C21.4032 1.33544 20.392 0.98652 19.1902 0.82348C18.0336 0.666573 16.5634 0.6666 14.7404 0.666627Z" fill="#7F58EC" />
                        </g>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 13.6667C12 14.4031 11.4031 15 10.6667 15H6.66671C5.93033 15 5.33337 14.4031 5.33337 13.6667C5.33337 12.9303 5.93033 12.3334 6.66671 12.3334H10.6667C11.4031 12.3334 12 12.9303 12 13.6667Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.33333C16 9.06971 15.4031 9.66667 14.6667 9.66667H6.66671C5.93033 9.66667 5.33337 9.06971 5.33337 8.33333C5.33337 7.59696 5.93033 7 6.66671 7H14.6667C15.4031 7 16 7.59696 16 8.33333Z" fill="white" />
                        <defs>
                          <filter id="filter0_i_2139_1092" x="0.333374" y="0.666626" width="23.3334" height="32.6666" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2139_1092" />
                          </filter>
                        </defs>
                      </svg>
                        :
                        <svg
                          width="24"
                          height="30"
                          viewBox="0 0 24 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.7404 0.666657H9.25964C7.4367 0.666631 5.9664 0.666604 4.80986 0.823511C3.60807 0.986551 2.59686 1.33547 1.79467 2.14491C0.993724 2.95311 0.64959 3.96979 0.48859 5.17815C0.333337 6.34336 0.33335 7.82538 0.333377 9.6663V23.9344C0.333323 24.8793 0.33327 25.6931 0.41991 26.3159C0.508737 26.9544 0.723337 27.6809 1.40783 28.1219C2.41918 28.7732 3.54004 28.3223 4.1695 27.9929C4.52996 27.8044 5.54696 27.1153 5.82798 26.9223C6.19172 26.6928 6.39311 26.5707 6.54391 26.4999C6.57284 26.4688 6.66831 26.4275 6.81876 26.5103C6.97104 26.5865 7.16559 26.7079 7.50546 26.9223L10.089 28.5515C10.4018 28.7488 10.698 28.9357 10.9652 29.0676C11.2635 29.2148 11.601 29.3333 12 29.3333C12.3991 29.3333 12.7366 29.2148 13.0348 29.0676C13.302 28.9357 13.5982 28.7489 13.911 28.5516L16.4947 26.9223C16.8584 26.6928 17.0598 26.5707 17.2106 26.4999C17.244 26.4715 17.3458 26.4337 17.4854 26.5103C17.6378 26.5865 18.5106 27.1737 18.8504 27.3881C19.1314 27.5812 19.4702 27.8044 19.8306 27.9929C20.46 28.3223 21.581 28.7732 22.5923 28.1219C23.2767 27.6809 23.4914 26.9544 23.5802 26.3159C23.6668 25.6931 23.6667 24.8793 23.6667 23.9344V9.66636C23.6667 7.82542 23.6667 6.34338 23.5115 5.17815C23.3504 3.96979 23.0063 2.95311 22.2054 2.14491C21.4032 1.33547 20.392 0.986551 19.1902 0.823511C18.0336 0.666604 16.5634 0.666631 14.7404 0.666657Z"
                            fill="#9371F0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 13.6667C12 14.4031 11.4031 15 10.6667 15H6.66671C5.93033 15 5.33337 14.4031 5.33337 13.6667C5.33337 12.9303 5.93033 12.3333 6.66671 12.3333H10.6667C11.4031 12.3333 12 12.9303 12 13.6667Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 8.33333C16 9.06971 15.4031 9.66667 14.6667 9.66667H6.66671C5.93033 9.66667 5.33337 9.06971 5.33337 8.33333C5.33337 7.59696 5.93033 7 6.66671 7H14.6667C15.4031 7 16 7.59696 16 8.33333Z"
                            fill="white"
                          />
                        </svg>
                      }
                    </span>{" "}
                    Bill & Payments
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Active == "Api Setting" ? "active" : ""
                      }`}
                    href="/Merchant/ApiSetting"
                  >
                    <span>
                      {Active == "Api Setting" ? <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_i_54_32)">
                          <path d="M14.0763 0.333252C16.9972 0.333239 19.2915 0.333226 21.0827 0.574039C22.9182 0.820826 24.3746 1.33688 25.5188 2.48113C26.6631 3.6254 27.1791 5.08175 27.4259 6.91728C27.6667 8.70851 27.6667 11.0027 27.6667 13.9237V14.0762C27.6667 16.9971 27.6667 19.2914 27.4259 21.0826C27.1791 22.9181 26.6631 24.3745 25.5188 25.5187C24.3746 26.663 22.9182 27.179 21.0827 27.4258C19.2915 27.6666 16.9972 27.6666 14.0763 27.6666H13.9238C11.0028 27.6666 8.70863 27.6666 6.9174 27.4258C5.08187 27.179 3.62552 26.663 2.48125 25.5187C1.337 24.3745 0.820948 22.9181 0.574161 21.0826C0.333348 19.2914 0.333361 16.9971 0.333374 14.0762V13.9237C0.333361 11.0027 0.333348 8.70851 0.574161 6.91728C0.820948 5.08175 1.337 3.6254 2.48125 2.48113C3.62552 1.33688 5.08187 0.820826 6.9174 0.574039C8.70863 0.333226 11.0029 0.333239 13.9238 0.333252H14.0763Z" fill="#7F58EC" />
                        </g>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34582 9.00056C8.76265 9.00564 9.13261 9.26876 9.2742 9.66084L11.4327 15.6381L12.2567 17.6159C12.4691 18.1257 12.228 18.7112 11.7183 18.9236C11.2085 19.136 10.623 18.8949 10.4106 18.3851L9.83364 17.0005H6.72107L6.28233 18.3167C6.10768 18.8407 5.54136 19.1239 5.01741 18.9492C4.49348 18.7745 4.21032 18.2083 4.38496 17.6843L5.05163 15.6843C5.05681 15.6687 5.06237 15.6533 5.0683 15.638L7.40164 9.63804C7.55273 9.24952 7.92898 8.9955 8.34582 9.00056ZM7.46216 15.0005H9.076L8.29896 12.8487L7.46216 15.0005Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5469 9.00029C15.5661 9.00041 15.584 9.00053 15.6005 9.00053H17.3338C18.9906 9.00053 20.3338 10.3437 20.3338 12.0005C20.3338 13.6573 18.9906 15.0005 17.3338 15.0005H15.6672V18.0005C15.6672 18.5528 15.2194 19.0005 14.6672 19.0005C14.1149 19.0005 13.6672 18.5528 13.6672 18.0005V10.9339C13.6672 10.9174 13.667 10.8995 13.6669 10.8803C13.6658 10.7015 13.664 10.4132 13.7488 10.1522C13.9136 9.64477 14.3114 9.24697 14.8188 9.08211C15.0798 8.99728 15.3681 8.99915 15.5469 9.00029ZM15.6672 13.0005H17.3338C17.8861 13.0005 18.3338 12.5528 18.3338 12.0005C18.3338 11.4483 17.8861 11.0005 17.3338 11.0005H15.6672V13.0005Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6666 9.00049C23.2189 9.00049 23.6666 9.44819 23.6666 10.0005V18.0005C23.6666 18.5528 23.2189 19.0005 22.6666 19.0005C22.1144 19.0005 21.6666 18.5528 21.6666 18.0005V10.0005C21.6666 9.44819 22.1144 9.00049 22.6666 9.00049Z" fill="white" />
                        <defs>
                          <filter id="filter0_i_54_32" x="0.333374" y="0.333252" width="27.3334" height="31.3333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_54_32" />
                          </filter>
                        </defs>
                      </svg>
                        :
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0763 0.333344C16.9972 0.33333 19.2915 0.333317 21.0827 0.57413C22.9182 0.820917 24.3746 1.33697 25.5188 2.48122C26.6631 3.62549 27.1791 5.08184 27.4259 6.91737C27.6667 8.7086 27.6667 11.0028 27.6667 13.9237V14.0763C27.6667 16.9972 27.6667 19.2915 27.4259 21.0827C27.1791 22.9181 26.6631 24.3745 25.5188 25.5188C24.3746 26.6631 22.9182 27.1791 21.0827 27.4259C19.2915 27.6667 16.9972 27.6667 14.0763 27.6667H13.9238C11.0028 27.6667 8.70863 27.6667 6.9174 27.4259C5.08187 27.1791 3.62552 26.6631 2.48125 25.5188C1.337 24.3745 0.820948 22.9181 0.574161 21.0827C0.333348 19.2915 0.333361 16.9972 0.333374 14.0763V13.9237C0.333361 11.0028 0.333348 8.7086 0.574161 6.91737C0.820948 5.08184 1.337 3.62549 2.48125 2.48122C3.62552 1.33697 5.08187 0.820917 6.9174 0.57413C8.70863 0.333317 11.0029 0.33333 13.9238 0.333344H14.0763Z"
                            fill="#9371F0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.34582 9.00056C8.76265 9.00564 9.13261 9.26876 9.2742 9.66084L11.4327 15.6381L12.2567 17.6159C12.4691 18.1257 12.228 18.7112 11.7183 18.9236C11.2085 19.136 10.623 18.8949 10.4106 18.3851L9.83364 17.0005H6.72107L6.28233 18.3167C6.10768 18.8407 5.54136 19.1239 5.01741 18.9492C4.49348 18.7745 4.21032 18.2083 4.38496 17.6843L5.05163 15.6843C5.05681 15.6687 5.06237 15.6533 5.0683 15.638L7.40164 9.63804C7.55273 9.24952 7.92898 8.9955 8.34582 9.00056ZM7.46216 15.0005H9.076L8.29896 12.8487L7.46216 15.0005Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.5469 9.00029C15.5661 9.00041 15.584 9.00053 15.6005 9.00053H17.3338C18.9906 9.00053 20.3338 10.3437 20.3338 12.0005C20.3338 13.6573 18.9906 15.0005 17.3338 15.0005H15.6672V18.0005C15.6672 18.5528 15.2194 19.0005 14.6672 19.0005C14.1149 19.0005 13.6672 18.5528 13.6672 18.0005V10.9339C13.6672 10.9174 13.667 10.8995 13.6669 10.8803C13.6658 10.7015 13.664 10.4132 13.7488 10.1522C13.9136 9.64477 14.3114 9.24697 14.8188 9.08211C15.0798 8.99728 15.3681 8.99915 15.5469 9.00029ZM15.6672 13.0005H17.3338C17.8861 13.0005 18.3338 12.5528 18.3338 12.0005C18.3338 11.4483 17.8861 11.0005 17.3338 11.0005H15.6672V13.0005Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.6666 9.00049C23.2189 9.00049 23.6666 9.44819 23.6666 10.0005V18.0005C23.6666 18.5528 23.2189 19.0005 22.6666 19.0005C22.1144 19.0005 21.6666 18.5528 21.6666 18.0005V10.0005C21.6666 9.44819 22.1144 9.00049 22.6666 9.00049Z"
                            fill="white"
                          />
                        </svg>}
                    </span>{" "}
                    API Setting
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Active == "Plans" ? "active" : ""}`}
                    href={`/Merchant/SubscriptionPlan`}
                  >
                    <span>
                      {Active == 'Plans' ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M2 8.75C2.55229 8.75 3 9.1977 3 9.75V14.25C3 15.6925 3.00213 16.6737 3.10092 17.4086C3.19585 18.1146 3.36322 18.4416 3.58579 18.6642C3.80836 18.8868 4.13538 19.0542 4.84143 19.1491C5.57625 19.2479 6.55752 19.25 8 19.25H14C15.4425 19.25 16.4238 19.2479 17.1586 19.1491C17.8646 19.0542 18.1916 18.8868 18.4142 18.6642C18.6368 18.4416 18.8042 18.1146 18.8991 17.4086C18.9979 16.6737 19 15.6925 19 14.25V9.75C19 9.1977 19.4477 8.75 20 8.75C20.5523 8.75 21 9.1977 21 9.75V14.3205C21 15.6747 21.0001 16.7913 20.8813 17.6751C20.7565 18.6029 20.4845 19.4223 19.8284 20.0784C19.1723 20.7345 18.3529 21.0065 17.4251 21.1312C16.5413 21.2501 15.4247 21.25 14.0706 21.25H7.92943C6.57531 21.25 5.4587 21.2501 4.57494 21.1312C3.64711 21.0065 2.82768 20.7345 2.17158 20.0784C1.51547 19.4223 1.2435 18.6029 1.11875 17.6751C0.999941 16.7913 0.999971 15.6747 1 14.3206V9.75C1 9.1977 1.44772 8.75 2 8.75Z" fill="white" />
                        <path d="M2.19143 3.45934C2.19143 1.95786 3.41603 0.75 4.91512 0.75H17.0849C18.584 0.75 19.8086 1.95786 19.8086 3.45934C19.8086 4.00972 19.9532 4.55089 20.2287 5.02939L21.2149 6.74274C21.4737 7.19195 21.6839 7.55669 21.7347 8.16669C21.7553 8.41456 21.7576 8.62312 21.726 8.82441C21.6958 9.0172 21.6381 9.1717 21.5956 9.2854L21.5894 9.3023C21.0565 10.7329 19.6723 11.75 18.0513 11.75C16.695 11.75 15.5023 11.037 14.8374 9.9644C13.9338 11.0575 12.5446 11.75 11 11.75C9.4554 11.75 8.06617 11.0575 7.16259 9.9644C6.49773 11.037 5.30506 11.75 3.94875 11.75C2.32768 11.75 0.943552 10.7329 0.410652 9.3022L0.404362 9.2854C0.361902 9.1717 0.304212 9.0172 0.273972 8.82441C0.242402 8.62312 0.244692 8.41457 0.265332 8.16669C0.316132 7.55668 0.526282 7.19195 0.785092 6.74274L1.77133 5.02939C2.04677 4.55089 2.19143 4.00972 2.19143 3.45934Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16.5C5 15.9477 5.44772 15.5 6 15.5H10C10.5523 15.5 11 15.9477 11 16.5C11 17.0523 10.5523 17.5 10 17.5H6C5.44772 17.5 5 17.0523 5 16.5Z" fill="white" />
                      </svg> :
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 8.75C2.55229 8.75 3 9.1977 3 9.75V14.25C3 15.6925 3.00213 16.6737 3.10092 17.4086C3.19585 18.1146 3.36322 18.4416 3.58579 18.6642C3.80836 18.8868 4.13538 19.0542 4.84143 19.1491C5.57625 19.2479 6.55752 19.25 8 19.25H14C15.4425 19.25 16.4238 19.2479 17.1586 19.1491C17.8646 19.0542 18.1916 18.8868 18.4142 18.6642C18.6368 18.4416 18.8042 18.1146 18.8991 17.4086C18.9979 16.6737 19 15.6925 19 14.25V9.75C19 9.1977 19.4477 8.75 20 8.75C20.5523 8.75 21 9.1977 21 9.75V14.3205C21 15.6747 21.0001 16.7913 20.8813 17.6751C20.7565 18.6029 20.4845 19.4223 19.8284 20.0784C19.1723 20.7345 18.3529 21.0065 17.4251 21.1312C16.5413 21.2501 15.4247 21.25 14.0706 21.25H7.92943C6.57531 21.25 5.4587 21.2501 4.57494 21.1312C3.64711 21.0065 2.82768 20.7345 2.17158 20.0784C1.51547 19.4223 1.2435 18.6029 1.11875 17.6751C0.999941 16.7913 0.999971 15.6747 1 14.3206V9.75C1 9.1977 1.44772 8.75 2 8.75Z"
                            fill="#9371F0"
                          />
                          <path
                            d="M2.19143 3.45934C2.19143 1.95786 3.41603 0.75 4.91512 0.75H17.0849C18.584 0.75 19.8086 1.95786 19.8086 3.45934C19.8086 4.00972 19.9532 4.55089 20.2287 5.02939L21.2149 6.74274C21.4737 7.19195 21.6839 7.55669 21.7347 8.16669C21.7553 8.41456 21.7576 8.62312 21.726 8.82441C21.6958 9.0172 21.6381 9.1717 21.5956 9.2854L21.5894 9.3023C21.0565 10.7329 19.6723 11.75 18.0513 11.75C16.695 11.75 15.5023 11.037 14.8374 9.9644C13.9338 11.0575 12.5446 11.75 11 11.75C9.4554 11.75 8.06617 11.0575 7.16259 9.9644C6.49773 11.037 5.30506 11.75 3.94875 11.75C2.32768 11.75 0.943552 10.7329 0.410652 9.3022L0.404362 9.2854C0.361902 9.1717 0.304212 9.0172 0.273972 8.82441C0.242402 8.62312 0.244692 8.41457 0.265332 8.16669C0.316132 7.55668 0.526282 7.19195 0.785092 6.74274L1.77133 5.02939C2.04677 4.55089 2.19143 4.00972 2.19143 3.45934Z"
                            fill="#9371F0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16.5C5 15.9477 5.44772 15.5 6 15.5H10C10.5523 15.5 11 15.9477 11 16.5C11 17.0523 10.5523 17.5 10 17.5H6C5.44772 17.5 5 17.0523 5 16.5Z"
                            fill="#9371F0"
                          />
                        </svg>}
                    </span>{" "}
                    Plans
                  </a>
                </li>
              </ul>
            </div>
            <div className="pt-0">
              {Active == 'Dashboard' ?
                <div className="bg-light p-3 rounded sidenav-profile-box custom-sidenav-box">
                  <span>
                    {data?.credits} credits left <br />
                  </span>
                  <div className="progress-bar-box mt-1">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${data.credits === 0 ? 1 : 100 * ((data.credits - (data.successCredits + data.failedCredits)) / data.credits)}%`,
                      }}
                    ></div>

                  </div>
                  <a
                    href="/Merchant/SubscriptionPlan"
                    className="custom-btn-light w-100 mt-2"
                  >
                    Upgrade Plan <span>🚀</span>
                  </a>
                </div> : ''
              }
              <div className="bg-light p-3 rounded mt-3 custom-sidenav-box">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="sidenav-profile-box">
                    <p className="mb-0">{data.name}</p>
                    <small>{data.email}</small>
                  </div>
                  <div
                    data-bs-toggle="modal" data-bs-target="#LogOutConfirm"
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0763 2.33333C18.9972 2.33332 21.2915 2.33331 23.0827 2.57412C24.9182 2.82091 26.3746 3.33696 27.5188 4.48121C28.6631 5.62548 29.1791 7.08183 29.4259 8.91736C29.6667 10.7086 29.6667 13.0028 29.6667 15.9237V16.0763C29.6667 18.9972 29.6667 21.2915 29.4259 23.0827C29.1791 24.9181 28.6631 26.3745 27.5188 27.5188C26.3746 28.6631 24.9182 29.1791 23.0827 29.4259C21.2915 29.6667 18.9972 29.6667 16.0763 29.6667H15.9238C13.0028 29.6667 10.7086 29.6667 8.9174 29.4259C7.08187 29.1791 5.62552 28.6631 4.48125 27.5188C3.337 26.3745 2.82095 24.9181 2.57416 23.0827C2.33335 21.2915 2.33336 18.9972 2.33337 16.0763V15.9237C2.33336 13.0028 2.33335 10.7086 2.57416 8.91736C2.82095 7.08183 3.337 5.62548 4.48125 4.48121C5.62552 3.33696 7.08187 2.82091 8.9174 2.57412C10.7086 2.33331 13.0029 2.33332 15.9238 2.33333H16.0763Z"
                        fill="#9371F0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.3064 10.7896C13.5052 11.0879 13.424 11.4905 13.125 11.6889C11.7223 12.6196 10.8 14.2093 10.8 16.0135C10.8 18.8795 13.1281 21.2027 16 21.2027C18.8719 21.2027 21.2 18.8795 21.2 16.0135C21.2 14.2093 20.2777 12.6196 18.8749 11.6889C18.576 11.4905 18.4948 11.0879 18.6936 10.7896C18.8923 10.4913 19.2958 10.4102 19.5947 10.6086C21.3445 11.7695 22.5 13.7568 22.5 16.0135C22.5 19.5959 19.5899 22.5 16 22.5C12.4101 22.5 9.5 19.5959 9.5 16.0135C9.5 13.7568 10.6555 11.7695 12.4053 10.6086C12.7042 10.4102 13.1077 10.4913 13.3064 10.7896Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 7.33333C16.5523 7.33333 17 7.75121 17 8.26667V14.4C17 14.9155 16.5523 15.3333 16 15.3333C15.4477 15.3333 15 14.9155 15 14.4V8.26667C15 7.75121 15.4477 7.33333 16 7.33333Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div className="modal fade" id="LogOutConfirm" tabindex="-1" aria-labelledby="LogOutConfirmLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{ fontWeight: '600' }}>
                Log Out
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='regenerateCloseBtnModal'></button>
            </div>
            <div className="modal-body">
              <div className="d-flex Regenerate-Modal-Body">
                <div className="col">
                  <h4 className='m-0'>Are you sure you want to log-off?</h4>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="simple-light-btn" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="simple-danger-btn col w-100"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                }}>Log Out</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
