// const url = 'http://3.108.41.84:8002/v1/';
// const url = "https://api-v1.aragon-ai.com/v1/";
const url = "https://api-v1.aiheadshotapi.com/v1/";

export const Login_Api = `${url}auth/login`;
export const Register_Api = `${url}auth/signup`;
export const Admin_Dashboard_Api = `${url}dashboard/get_admin_dashboard_data`;
export const Get_All_Merchant_Data_Api = `${url}users`;
export const Get_Single_Merchant_Data_Api = `${url}users/`;
export const Plan_Api = `${url}plans`;
export const Get_All_Plan_Api = `${url}plans/user_plans`;
export const Get_Current_User_Api = `${url}auth/me`;
export const User_Api = `${url}users`;
export const Regenerate_Api = `${url}users/generate_new_apikey`;
export const Update_User_Api = `${url}users/`;
export const Get_User_Payment_Api = `${url}users/get_user_payments/`;
export const Update_Admin_Setting_Api = `${url}settings/update_settings`;
export const Payment_List_Api = `${url}payments`;
export const Add_Payment_Api = `${url}payments`;
export const Get_User_Plan_Api = `${url}plans/user_plans`;
export const Cancel_Plan_Api = `${url}plans/cancel_current_plan/`;
export const GateWay_Api = `${url}paymentGateways`;
export const MerchantGateWay_Api = `${url}paymentGateways/get_for_merchants`;
export const LogOutAuthForToeknInvalid = () => {
  window.localStorage.clear();
  window.location.reload();
};
