import React, { useEffect, useState } from 'react';
import Sidebar from '../UI/Merchant/SideNav';
import axios from 'axios';
import $ from 'jquery';
import { Get_Current_User_Api, LogOutAuthForToeknInvalid } from '../Api';

const AccountSetting = () => {
    const [authToken, setauthToken] = useState(localStorage.getItem('authToken'));
    const [data, setdata] = useState({});
    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Get_Current_User_Api,
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                if (error.response.data.status == 401 || error.response.data.status == 403) {
                    LogOutAuthForToeknInvalid();
                }
            });

    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="row min-vh-100">
                    <Sidebar Active={'Account Setting'} />
                    <main className="col py-3 ms-auto custom-container-align d-flex">
                        <div className="container d-flex flex-column justify-content-between">
                            <div className="content-box-wrapper">
                                <div className="custom-heading">
                                    <h2>
                                        <span className='pe-1'>
                                            <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" d="M17.3936 2.99985C19.8424 2.99983 21.7618 2.99982 23.2846 3.16482C24.8388 3.33318 26.0978 3.68294 27.173 4.46405C27.8238 4.93693 28.3962 5.5093 28.869 6.16015C29.6502 7.23526 30 8.49443 30.1684 10.0485C30.3333 11.5713 30.3333 13.4907 30.3333 15.9393V16.0603C30.3333 18.5089 30.3333 20.4284 30.1684 21.9512C30 23.5052 29.6502 24.7644 28.869 25.8395C28.3962 26.4904 27.8238 27.0628 27.173 27.5356C26.0978 28.3168 24.8388 28.6665 23.2846 28.8349C21.7618 28.9999 19.8424 28.9999 17.3937 28.9999H14.6062C12.1575 28.9999 10.2381 28.9999 8.71527 28.8349C7.16121 28.6665 5.90204 28.3168 4.82695 27.5356C4.17608 27.0628 3.60371 26.4904 3.13083 25.8395C2.34972 24.7644 1.99997 23.5052 1.8316 21.9512C1.66661 20.4284 1.66661 18.5089 1.66663 16.0601V15.9395C1.66661 13.4908 1.66661 11.5713 1.8316 10.0485C1.99997 8.49443 2.34972 7.23526 3.13083 6.16015C3.60371 5.5093 4.17608 4.93693 4.82695 4.46405C5.90204 3.68294 7.16121 3.33318 8.71527 3.16482C10.2381 2.99982 12.1576 2.99983 14.6064 2.99985H17.3936Z" fill="#9371F0" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3334 11.9998C17.3334 11.2635 17.9303 10.6665 18.6667 10.6665H24C24.7364 10.6665 25.3334 11.2635 25.3334 11.9998C25.3334 12.7362 24.7364 13.3332 24 13.3332H18.6667C17.9303 13.3332 17.3334 12.7362 17.3334 11.9998Z" fill="#9371F0" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3334 16.6665C17.3334 15.9301 17.9303 15.3332 18.6667 15.3332H22.6667C23.4031 15.3332 24 15.9301 24 16.6665C24 17.4029 23.4031 17.9999 22.6667 17.9999H18.6667C17.9303 17.9999 17.3334 17.4029 17.3334 16.6665Z" fill="#9371F0" />
                                                <path d="M6.24287 22.2387C5.74269 22.0045 5.52703 21.4092 5.76119 20.9091C6.77427 18.7449 9.09878 17.6993 11.279 17.6671C13.4714 17.6348 15.8356 18.6227 16.9058 20.9091C17.1401 21.4092 16.9244 22.0045 16.4242 22.2387C16.2868 22.3029 16.1422 22.3333 16 22.3332H6.66706C6.52479 22.3333 6.38025 22.3029 6.24287 22.2387Z" fill="#9371F0" />
                                                <path d="M7.66663 11.9997C7.66663 9.97462 9.30825 8.33301 11.3333 8.33301C13.3584 8.33301 15 9.97462 15 11.9997C15 14.0247 13.3584 15.6664 11.3333 15.6664C9.30825 15.6664 7.66663 14.0247 7.66663 11.9997Z" fill="#9371F0" />
                                            </svg>
                                        </span>
                                        Account Details
                                    </h2>
                                </div>

                                <div className="custom-grid-wrapper-box account-setting-grid-box mt-3" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                                    <div className="custom-grid-single-box">
                                        <h4>Account Holder</h4>
                                        <h3 className='m-0' style={{ color: '#000' }}>
                                            <span className='pe-2'>
                                                <svg width="25" height="26" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.7415 20.4496C23.8965 20.5418 24.0881 20.65 24.3051 20.7724C25.2555 21.3085 26.6921 22.119 27.6764 23.0824C28.2919 23.6849 28.8768 24.4789 28.9831 25.4517C29.0961 26.4861 28.6449 27.4569 27.7396 28.3194C26.1776 29.8074 24.3032 31 21.8788 31H10.1211C7.69673 31 5.82236 29.8074 4.26042 28.3194C3.35506 27.4569 2.90378 26.4861 3.01686 25.4517C3.12318 24.4789 3.70805 23.6849 4.32361 23.0824C5.30784 22.119 6.74452 21.3085 7.69493 20.7724C7.91181 20.6501 8.10339 20.5418 8.25848 20.4496C12.9972 17.6278 19.0028 17.6278 23.7415 20.4496Z" fill="#A7A7A7" />
                                                    <path opacity="0.4" d="M9 9.33331C9 5.46731 12.134 2.33331 16 2.33331C19.866 2.33331 23 5.46731 23 9.33331C23 13.1993 19.866 16.3333 16 16.3333C12.134 16.3333 9 13.1993 9 9.33331Z" fill="#A7A7A7" />
                                                </svg>
                                            </span>
                                            {data.name}</h3>
                                    </div>
                                    <div className="custom-grid-single-box">
                                        <h4>Company Name</h4>
                                        <h3 className='m-0' style={{ color: '#000' }}>
                                            <span className='pe-2'>
                                                <svg width="25" height="26" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M17.7723 5.2842C18.6615 5.54228 19.4136 5.76059 20.0061 6.01321C20.6379 6.28261 21.1839 6.62864 21.5987 7.19588C22.0109 7.7598 22.1811 8.38887 22.2595 9.07989C22.3336 9.73425 22.3336 10.5395 22.3336 11.5017V29.9999C22.3336 30.5521 21.8859 30.9999 21.3336 30.9999H4.00024C3.44796 30.9999 3.00024 30.5521 3.00024 29.9999V9.54505C3.00022 7.93741 3.00018 6.62437 3.14179 5.61411C3.28843 4.568 3.61235 3.63403 4.43659 2.98513C5.2698 2.32917 6.24619 2.25333 7.27595 2.3912C8.25927 2.52284 9.48391 2.87835 10.9698 3.30971L17.7723 5.2842Z" fill="#A7A7A7" />
                                                    <path d="M22.3335 13.0053V15.7895L23.4832 16.1343C24.4287 16.418 25.0279 16.5999 25.4639 16.788C25.8678 16.9623 25.9975 17.0879 26.0702 17.1856C26.143 17.2833 26.226 17.4437 26.2768 17.8807C26.3316 18.3523 26.3338 18.9784 26.3338 19.9656V29.9975H29.0004V19.894C29.0006 18.9995 29.0006 18.2165 28.9256 17.5725C28.8447 16.8765 28.6642 16.2047 28.2096 15.5939C27.7551 14.9829 27.1635 14.6169 26.5202 14.3395C25.9247 14.0825 25.1748 13.8577 24.318 13.6007L22.3335 13.0053Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66699 11.3334C9.66699 10.7812 10.1147 10.3334 10.667 10.3334H14.667C15.2193 10.3334 15.667 10.7812 15.667 11.3334C15.667 11.8857 15.2193 12.3334 14.667 12.3334H10.667C10.1147 12.3334 9.66699 11.8857 9.66699 11.3334ZM9.66699 16.6668C9.66699 16.1145 10.1147 15.6668 10.667 15.6668H14.667C15.2193 15.6668 15.667 16.1145 15.667 16.6668C15.667 17.2191 15.2193 17.6668 14.667 17.6668H10.667C10.1147 17.6668 9.66699 17.2191 9.66699 16.6668Z" fill="#A7A7A7" />
                                                    <path d="M11.9405 21.0001H12.0002H13.3335H13.3931C13.9703 21 14.4994 21 14.9298 21.0579C15.4038 21.1216 15.9047 21.2715 16.3167 21.6836C16.7288 22.0956 16.8787 22.5965 16.9424 23.0705C17.0003 23.5009 17.0002 24.03 17.0002 24.6071V30.0001H15.0002V24.6668C15.0002 24.01 14.998 23.618 14.9603 23.3371C14.943 23.2083 14.9226 23.1437 14.9106 23.1148C14.8816 23.1028 14.792 23.0573 14.6632 23.04C14.3823 23.0023 13.9903 23.0001 13.3335 23.0001H12.0002C11.3434 23.0001 10.9514 23.0023 10.6704 23.04C10.5416 23.0573 10.4772 23.0777 10.4481 23.0896C10.4362 23.1187 10.3907 23.2083 10.3734 23.3371C10.3356 23.618 10.3335 24.01 10.3335 24.6668V30.0001H8.3335V24.6071C8.33343 24.03 8.33337 23.5009 8.39123 23.0705C8.45495 22.5965 8.60489 22.0956 9.01691 21.6836C9.42895 21.2715 9.92991 21.1216 10.4039 21.0579C10.8343 21 11.3634 21 11.9405 21.0001Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3335 29.6668C1.3335 28.9304 1.93046 28.3335 2.66683 28.3335H29.3335C30.0699 28.3335 30.6669 28.9304 30.6669 29.6668C30.6669 30.4032 30.0699 31.0001 29.3335 31.0001H2.66683C1.93046 31.0001 1.3335 30.4032 1.3335 29.6668Z" fill="#A7A7A7" />
                                                </svg>
                                            </span>
                                            {data.company}</h3>
                                    </div>
                                    <div className="custom-grid-single-box">
                                        <h4>Email ID</h4>
                                        <h3 className='m-0' style={{ color: '#000' }}>
                                            <span className='pe-2'>
                                                <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.69006 14.2153C1.73173 12.2613 1.76616 10.6469 1.99364 9.33856L4.99178 10.8814C4.85753 10.834 4.7544 10.8301 4.6614 10.8814C4.46561 10.9893 4.45272 11.2597 4.42693 11.8004C4.39217 12.5291 4.37333 13.3739 4.352 14.3727C4.3244 15.6653 4.32441 16.334 4.35202 17.6268C4.39644 19.7068 4.4301 21.1188 4.61905 22.2055C4.79697 23.2287 5.0938 23.8367 5.60158 24.3457C6.10409 24.8495 6.72032 25.1515 7.77802 25.3355C8.89561 25.5299 10.3533 25.5697 12.4905 25.6235C15.0536 25.6877 16.9464 25.6877 19.5094 25.6234C21.6466 25.5697 23.1044 25.5299 24.222 25.3355C25.2796 25.1515 25.8958 24.8495 26.3984 24.3457C26.9061 23.8367 27.203 23.2287 27.3809 22.2055C27.5698 21.1188 27.6036 19.7067 27.648 17.6267C27.6756 16.334 27.6756 15.6653 27.648 14.3727C27.6266 13.3737 27.6077 12.5288 27.573 11.8001C27.5472 11.2594 27.5342 10.9891 27.3385 10.8811C27.2453 10.8298 27.142 10.8337 27.0073 10.8814L30.0062 9.33856C30.2337 10.6468 30.2682 12.2614 30.3098 14.2153L30.312 14.3159C30.3404 15.6464 30.3404 16.3529 30.312 17.6835L30.3098 17.784C30.2682 19.738 30.2338 21.3525 30.0062 22.6608C29.764 24.0548 29.2854 25.2221 28.2861 26.224C27.2816 27.2311 26.0956 27.7109 24.6792 27.9573C23.3445 28.1895 21.6886 28.2309 19.6761 28.2815L19.5764 28.2839C16.9686 28.3493 15.0313 28.3493 12.4236 28.284L12.3238 28.2815C10.3113 28.2309 8.65545 28.1895 7.32084 27.9573C5.90434 27.7109 4.71833 27.2311 3.71378 26.224C2.71452 25.2221 2.23605 24.0549 1.99365 22.6608C1.76617 21.3525 1.73174 19.738 1.69009 17.784L1.68794 17.6835C1.65953 16.3529 1.65952 15.6464 1.68792 14.3159L1.69006 14.2153Z" fill="#A7A7A7" />
                                                    <path opacity="0.4" d="M12.4247 3.71573C15.0324 3.6503 16.9698 3.65029 19.5774 3.71573L19.6773 3.71823C21.6897 3.76868 23.3456 3.81018 24.6802 4.04233C25.174 4.1282 25.6396 4.24244 26.0794 4.39746C26.1962 4.42496 26.3085 4.46804 26.413 4.52517C27.1009 4.81025 27.7228 5.20969 28.2873 5.77564C29.2865 6.77746 29.765 7.94478 30.0074 9.33886L27.0084 10.8817C26.8602 10.9342 26.6741 11.0397 26.4218 11.1826L20.7741 14.3827C19.0413 15.3645 17.5757 16 16.0008 16C14.4258 16 12.9604 15.3645 11.2276 14.3827L5.58035 11.1829C5.32764 11.0397 5.14127 10.9341 4.99291 10.8817L1.99475 9.33886C2.23715 7.94478 2.71562 6.77746 3.71488 5.77564C4.71943 4.76853 5.90544 4.2887 7.32194 4.04232C8.65656 3.81018 10.3124 3.76868 12.3249 3.71824L12.4247 3.71573Z" fill="#A7A7A7" />
                                                </svg>
                                            </span>
                                            {data.email}</h3>
                                    </div>
                                    <div className="custom-grid-single-box">
                                        <h4>WhatsApp Number</h4>
                                        <h3 className='m-0' style={{ color: '#000' }}>
                                            <span className='pe-2'>
                                                <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M1.66678 16C1.66678 8.08391 8.08403 1.66666 16.0001 1.66666C23.9162 1.66666 30.3334 8.08391 30.3334 16C30.3334 23.9161 23.9162 30.3333 16.0001 30.3333C14.0255 30.3333 12.1419 29.9335 10.4275 29.2095C9.97776 29.0195 9.67387 28.8915 9.44034 28.806C9.20214 28.7187 9.11598 28.7064 9.09376 28.7049C8.96855 28.6963 8.79831 28.7279 7.99027 28.9441L2.92524 30.2993C2.58024 30.3916 2.2122 30.2929 1.95967 30.0404C1.70714 29.7879 1.60844 29.4199 1.70075 29.0748L3.05598 24.0099C3.27218 23.2017 3.30386 23.0316 3.2952 22.9064C3.29366 22.8841 3.2814 22.798 3.19418 22.5597C3.10864 22.3263 2.9806 22.0224 2.79066 21.5727C2.0666 19.8583 1.66678 17.9745 1.66678 16Z" fill="#A7A7A7" />
                                                    <path d="M11.8402 8.33314L11.9638 8.33328C12.2576 8.33328 12.7524 8.34234 13.2098 8.53294C13.7562 8.76063 14.2064 9.22969 14.3216 9.96494L14.3228 9.97253C14.4656 10.8839 14.5743 11.5766 14.6455 12.0665C14.6811 12.3117 14.7087 12.5153 14.7268 12.6745C14.7418 12.8053 14.7604 12.9854 14.7516 13.1361C14.7134 13.7888 14.4486 14.308 14.1731 14.7101C13.9976 14.966 13.7628 15.244 13.5782 15.4627C13.5056 15.5487 13.4408 15.6253 13.3903 15.688L13.3073 15.7911C12.9903 16.1848 12.8319 16.3816 12.8369 16.6243C12.8419 16.8671 12.9977 17.0451 13.3094 17.4012C13.5078 17.6277 13.7154 17.8499 13.9324 18.0669C14.1494 18.2839 14.3715 18.4916 14.598 18.6899C14.9542 19.0016 15.1323 19.1575 15.375 19.1624C15.6176 19.1675 15.8146 19.0089 16.2082 18.692L16.3114 18.6089C16.3739 18.5585 16.4507 18.4937 16.5366 18.4212C16.7552 18.2365 17.0334 18.0016 17.2892 17.8263C17.6914 17.5507 18.2104 17.2859 18.8632 17.2477C19.0139 17.2389 19.1939 17.2576 19.3248 17.2724C19.484 17.2905 19.6875 17.3181 19.9327 17.3539C20.4227 17.4251 21.1154 17.5336 22.0267 17.6764L22.0343 17.6776C22.7696 17.7928 23.2387 18.2432 23.4663 18.7895C23.657 19.2468 23.666 19.7417 23.666 20.0355L23.6662 20.1591C23.6672 20.7987 23.6682 21.3887 23.4032 21.9688C23.2106 22.3904 22.8835 22.7491 22.5567 23.0103C22.23 23.2715 21.808 23.5115 21.3544 23.6065C20.7327 23.7367 20.2596 23.6293 19.7535 23.5145L19.6835 23.4987C17.1178 22.9192 14.6019 21.5648 12.5182 19.4811C10.4345 17.3973 9.08005 14.8815 8.50069 12.3158L8.48484 12.2458C8.36998 11.7397 8.26262 11.2666 8.39281 10.6448C8.48778 10.1912 8.72788 9.76926 8.98906 9.44253C9.25025 9.11578 9.60893 8.78865 10.0305 8.5961C10.6107 8.33106 11.2007 8.33206 11.8402 8.33314Z" fill="#A7A7A7" />
                                                </svg>
                                            </span>
                                            {data.mobileNumber}</h3>
                                    </div>
                                    <div className="custom-grid-single-box">
                                        <h4>GST Number</h4>
                                        <h3 className='m-0' style={{ color: '#000' }}>
                                            <span className='pe-2'>
                                                <svg width="25" height="26" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M14.6556 29.4217C13.3304 31.0816 10.6377 31.0816 9.31245 29.4217L8.57768 28.5014C8.4848 28.3852 8.3093 28.2981 8.08561 28.3088C7.86156 28.3194 7.70874 28.4213 7.63869 28.5325C7.07585 29.4254 6.11488 29.7466 5.27922 29.6832C4.46702 29.6214 3.5788 29.1792 3.16642 28.3006C2.90861 27.8992 3.00682 26.9984 3.08816 26.5982L5.66277 14.4153C5.97297 12.9474 6.22468 11.7563 6.54364 10.8256C6.87618 9.85535 7.31629 9.05789 8.06989 8.44882C8.82288 7.84026 9.6952 7.57633 10.7146 7.45234C11.6936 7.33327 12.9148 7.33329 14.4217 7.33331H17.5783C19.0852 7.33329 20.3064 7.33327 21.2853 7.45234C22.3048 7.57633 23.1771 7.84026 23.9301 8.44882C24.6837 9.05789 25.1239 9.85535 25.4564 10.8256C25.7753 11.7563 26.0271 12.9474 26.3372 14.4153L28.9119 26.5982C28.9932 26.9984 29.0913 27.8992 28.8336 28.3006C28.4212 29.1792 27.5329 29.6214 26.7208 29.6832C25.8851 29.7466 24.9241 29.4254 24.3613 28.5325C24.2912 28.4213 24.1384 28.3194 23.9144 28.3088C23.6907 28.2981 23.5152 28.3852 23.4223 28.5014L22.6876 29.4217C21.3623 31.0816 18.6696 31.0816 17.3444 29.4217C17.0529 29.0568 16.4687 28.7296 16 28.7296C15.5313 28.7296 14.9471 29.0568 14.6556 29.4217Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 13.6666C17 13.1143 16.5523 12.6666 16 12.6666H12C11.4477 12.6666 11 13.1143 11 13.6666C11 14.2189 11.4477 14.6666 12 14.6666H16C16.5523 14.6666 17 14.2189 17 13.6666Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6666 19C19.6666 18.4477 19.2189 18 18.6666 18H10.6666C10.1143 18 9.66663 18.4477 9.66663 19C9.66663 19.5523 10.1143 20 10.6666 20H18.6666C19.2189 20 19.6666 19.5523 19.6666 19Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09989 2C8.12922 2 8.15865 2.00002 8.18818 2.00002L23.9002 2C25.0521 1.99994 26.0496 1.99988 26.8494 2.10815C27.7122 2.22498 28.5344 2.48898 29.1958 3.15515C29.8508 3.81458 30.1038 4.62256 30.2168 5.46814C30.3229 6.26372 30.3229 7.25958 30.3229 8.42618V8.5075C30.3229 8.8266 30.3257 9.13319 30.3285 9.42515C30.3356 10.1862 30.3417 10.8478 30.2937 11.3728C30.2192 12.1879 30.0005 12.9463 29.3385 13.6129C28.8394 14.1155 28.0146 14.1307 27.4961 13.6469C26.9777 13.163 26.962 12.3634 27.461 11.8608C27.5618 11.7593 27.653 11.6392 27.6978 11.1497C27.734 10.7543 27.7296 10.282 27.7232 9.60042C27.7201 9.28524 27.7168 8.92531 27.7168 8.5075C27.7168 7.23699 27.7142 6.4065 27.6322 5.7924C27.5544 5.20988 27.4262 5.0158 27.3184 4.90722C27.2172 4.80536 27.0442 4.68536 26.4889 4.61019C25.8909 4.52922 25.0777 4.52632 23.8118 4.52632H8.18818C6.92245 4.52632 6.10923 4.52922 5.51118 4.61019C4.95585 4.68536 4.78285 4.80536 4.68172 4.90722C4.57388 5.0158 4.44566 5.20987 4.36787 5.7924C4.28589 6.4065 4.28333 7.23699 4.28333 8.5075C4.28333 8.9275 4.27986 9.28898 4.27685 9.60531C4.27035 10.2844 4.26585 10.7554 4.30181 11.1501C4.34643 11.6397 4.43762 11.7596 4.53813 11.8608C5.03722 12.3634 5.02153 13.163 4.50308 13.6469C3.98462 14.1307 3.15973 14.1155 2.66062 13.6129C1.99845 12.9461 1.7801 12.1874 1.70583 11.3724C1.65811 10.8486 1.66437 10.1883 1.67155 9.42916C1.67433 9.13596 1.67723 8.82803 1.67723 8.5075C1.67723 8.4803 1.67723 8.45318 1.67723 8.42616C1.67718 7.25956 1.67713 6.26372 1.78335 5.46814C1.89625 4.62256 2.14937 3.81458 2.80421 3.15515C3.46575 2.48898 4.28785 2.22498 5.1507 2.10815C5.95046 1.99988 6.94797 1.99994 8.09989 2Z" fill="#A7A7A7" />
                                                </svg>
                                            </span>
                                            {data.gst}</h3>
                                    </div>
                                </div>
                            </div>
                            {data.activePlan !== null && (
                                <div className="content-box-wrapper mt-3 plan-details-box">
                                    <div className="d-flex">
                                        <div className="custom-heading col">
                                            <h2>
                                                <span className="pe-1">
                                                    <svg
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            opacity="0.4"
                                                            d="M19.4432 9.33333C21.0239 9.3333 22.304 9.33329 23.3275 9.46193C24.3939 9.59597 25.3015 9.88144 26.0746 10.537C26.8463 11.1913 27.281 12.0424 27.5972 13.077C27.9015 14.0727 28.1262 15.3471 28.4043 16.9247L28.9164 19.8292C29.303 22.0212 29.6116 23.7717 29.6599 25.1567C29.7098 26.5845 29.4931 27.8091 28.657 28.8205C27.8187 29.8345 26.6602 30.2687 25.257 30.4711C23.8996 30.6667 22.1415 30.6667 19.9452 30.6667H12.0549C9.85854 30.6667 8.10043 30.6667 6.74311 30.4711C5.33987 30.2687 4.18133 29.8345 3.34307 28.8205C2.50691 27.8091 2.29031 26.5845 2.34009 25.1567C2.38837 23.7717 2.69706 22.0211 3.08359 19.8292L3.5957 16.9247C3.87386 15.3471 4.09852 14.0727 4.40284 13.077C4.71908 12.0424 5.15374 11.1913 5.92548 10.537C6.69859 9.88144 7.60619 9.59597 8.67252 9.46193C9.696 9.33329 10.9761 9.3333 12.5568 9.33333H19.4432Z"
                                                            fill="#9371F0"
                                                        />
                                                        <path
                                                            d="M15.9999 16.3333C17.5088 16.3333 18.5716 15.3045 18.6721 14.2112C18.7396 13.4779 19.3888 12.9381 20.1221 13.0056C20.8553 13.0731 21.3951 13.7223 21.3276 14.4555C21.0815 17.1304 18.6661 19 15.9999 19C13.3337 19 10.9183 17.1304 10.6722 14.4555C10.6047 13.7223 11.1445 13.0731 11.8778 13.0056C12.611 12.9381 13.2602 13.4779 13.3277 14.2112C13.4283 15.3045 14.4911 16.3333 15.9999 16.3333Z"
                                                            fill="#9371F0"
                                                        />
                                                        <path
                                                            d="M11.7547 7.66982C11.9387 5.5013 13.7844 3.83331 16 3.83331C18.2156 3.83331 20.0614 5.5013 20.2454 7.66982L20.3866 9.33445C21.368 9.33815 22.2174 9.35391 22.9482 9.42098L22.782 7.46221C22.488 3.99797 19.5395 1.33333 16 1.33333C12.4606 1.33333 9.51201 3.99797 9.21808 7.46221L9.05188 9.42098C9.78263 9.35391 10.632 9.33815 11.6135 9.33445L11.7547 7.66982Z"
                                                            fill="#9371F0"
                                                        />
                                                    </svg>
                                                </span>
                                                Plan Details
                                            </h2>
                                        </div>
                                        <div className="ps-2">
                                            <button className='stylish-btn' onClick={() => window.location.href = '/Merchant/SubscriptionPlan'}>
                                                <span className="pe-2">
                                                    <svg
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g filter="url(#filter0_i_54_32)">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.0001 0.666672C7.084 0.666672 0.666748 7.08392 0.666748 15C0.666748 22.9161 7.084 29.3333 15.0001 29.3333C22.9162 29.3333 29.3334 22.9161 29.3334 15C29.3334 7.08392 22.9162 0.666672 15.0001 0.666672ZM17.3691 7.81371C17.3691 7.08078 16.9735 6.42714 16.3346 6.14122C15.6585 5.8386 14.8551 6.02402 14.3674 6.68141L9.03537 13.8677C8.61012 14.4409 8.58632 15.1455 8.79415 15.6907C9.0004 16.2319 9.52952 16.8121 10.3524 16.8121H12.6305V22.1864C12.6305 22.9193 13.0261 23.5729 13.6649 23.8589C14.341 24.1615 15.1445 23.9761 15.6322 23.3187L20.9642 16.1324C21.3894 15.5592 21.4133 14.8547 21.2054 14.3093C20.9991 13.7683 20.4701 13.188 19.6471 13.188H17.3691V7.81371Z"
                                                                fill="#7F58EC"
                                                            />
                                                        </g>
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M17.3691 7.81371C17.3691 7.08077 16.9735 6.42713 16.3346 6.14121C15.6585 5.8386 14.8551 6.02402 14.3674 6.6814L9.03537 13.8677C8.61012 14.4409 8.58632 15.1455 8.79414 15.6907C9.0004 16.2319 9.52952 16.8121 10.3524 16.8121H12.6305V22.1864C12.6305 22.9193 13.0261 23.5729 13.6649 23.8589C14.341 24.1615 15.1445 23.9761 15.6322 23.3187L20.9642 16.1324C21.3894 15.5592 21.4133 14.8547 21.2054 14.3093C20.9991 13.7683 20.4701 13.188 19.6471 13.188H17.3691V7.81371Z"
                                                            fill="white"
                                                        />
                                                        <defs>
                                                            <filter
                                                                id="filter0_i_54_32"
                                                                x="0.666748"
                                                                y="0.666672"
                                                                width="28.6667"
                                                                height="32.6667"
                                                                filterUnits="userSpaceOnUse"
                                                                color-interpolation-filters="sRGB"
                                                            >
                                                                <feFlood
                                                                    flood-opacity="0"
                                                                    result="BackgroundImageFix"
                                                                />
                                                                <feBlend
                                                                    mode="normal"
                                                                    in="SourceGraphic"
                                                                    in2="BackgroundImageFix"
                                                                    result="shape"
                                                                />
                                                                <feColorMatrix
                                                                    in="SourceAlpha"
                                                                    type="matrix"
                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                    result="hardAlpha"
                                                                />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="2" />
                                                                <feComposite
                                                                    in2="hardAlpha"
                                                                    operator="arithmetic"
                                                                    k2="-1"
                                                                    k3="1"
                                                                />
                                                                <feColorMatrix
                                                                    type="matrix"
                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                                                                />
                                                                <feBlend
                                                                    mode="normal"
                                                                    in2="shape"
                                                                    result="effect1_innerShadow_54_32"
                                                                />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                Get Credits
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="custom-grid-wrapper-box mt-3"
                                        style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
                                    >
                                        <div className="custom-grid-single-box">
                                            <h4>Current Plan</h4>
                                            <h3 className="m-0" style={{ color: "#000" }}>
                                                <span className="pe-2">
                                                    <svg
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M11.3161 3.18849C13.3137 2.26439 14.6057 1.66667 16 1.66667C17.3943 1.66667 18.6863 2.26439 20.6839 3.18849L20.8956 3.2864L23.2481 4.37384C24.9309 5.15167 26.2971 5.78308 27.2333 6.39347C28.1701 7.00418 29 7.79923 29 9C29 10.2008 28.1701 10.9958 27.2333 11.6065C26.2971 12.2169 24.9311 12.8483 23.2481 13.6261L20.8956 14.7136L20.6839 14.8115C18.6863 15.7356 17.3943 16.3333 16 16.3333C14.6057 16.3333 13.3137 15.7356 11.3161 14.8115L11.1044 14.7136L8.75184 13.6261C7.06899 12.8483 5.70291 12.2169 4.7666 11.6065C3.82983 10.9958 3 10.2008 3 9C3 7.79923 3.82983 7.00418 4.7666 6.39347C5.70291 5.78308 7.06899 5.15167 8.75184 4.37384L11.1044 3.2864L11.3161 3.18849Z"
                                                            fill="#A7A7A7"
                                                        />
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M6.12721 20.5235C6.57452 21.0995 6.48187 21.9384 5.92027 22.3972C5.72584 22.556 5.64639 22.6619 5.61651 22.7128C5.59895 22.7428 5.59951 22.7496 5.59989 22.7543C5.59995 22.7549 5.6 22.7556 5.6 22.7563C5.6 22.7656 5.60073 22.7675 5.60132 22.7689C5.60125 22.7688 5.6014 22.7692 5.60132 22.7689C5.60276 22.7731 5.61255 22.7993 5.64953 22.8485C5.73319 22.9599 5.91372 23.1349 6.28272 23.3781C7.03771 23.8759 8.21197 24.4284 9.95864 25.2447L12.1749 26.2804C14.43 27.3343 15.206 27.666 16 27.666C16.794 27.666 17.57 27.3343 19.8251 26.2804L22.0413 25.2447C23.788 24.4284 24.9623 23.8757 25.7173 23.3781C26.0863 23.1349 26.2668 22.9599 26.3505 22.8485C26.3875 22.7993 26.3971 22.7736 26.3985 22.7695C26.3984 22.7697 26.3985 22.7693 26.3985 22.7695C26.3991 22.768 26.4 22.7656 26.4 22.7563C26.4 22.7556 26.4 22.7549 26.4001 22.7543C26.4005 22.7496 26.4011 22.7428 26.3835 22.7128C26.3536 22.6619 26.2741 22.556 26.0797 22.3972C25.5181 21.9384 25.4255 21.0995 25.8728 20.5235C26.3201 19.9475 27.138 19.8525 27.6996 20.3113C28.3971 20.8811 29 21.6645 29 22.7563C29 24.1356 28.0512 25.0096 27.1229 25.6216C26.1903 26.2363 24.8407 26.8669 23.2184 27.6251L20.9032 28.7069C20.8191 28.7463 20.7363 28.7851 20.6544 28.8233C18.7492 29.7149 17.4291 30.3327 16 30.3327C14.5709 30.3327 13.2508 29.7149 11.3456 28.8233C11.2638 28.7851 11.1809 28.7463 11.0969 28.7069L8.78167 27.6251C7.15933 26.8669 5.80975 26.2363 4.87709 25.6216C3.94876 25.0096 3 24.1356 3 22.7563C3 21.6645 3.60295 20.8811 4.30041 20.3113C4.86201 19.8525 5.67989 19.9475 6.12721 20.5235Z"
                                                            fill="#A7A7A7"
                                                        />
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M6.12721 13.6849C6.57452 14.2609 6.48187 15.0999 5.92027 15.5587C5.72584 15.7175 5.64639 15.8233 5.61651 15.8743C5.59895 15.9041 5.59951 15.9109 5.59989 15.9157C5.59995 15.9164 5.6 15.9171 5.6 15.9177C5.6 15.9271 5.60073 15.9289 5.60132 15.9304C5.60125 15.9303 5.6014 15.9307 5.60132 15.9304C5.60276 15.9345 5.61255 15.9608 5.64953 16.01C5.73319 16.1213 5.91372 16.2964 6.28272 16.5396C7.03771 17.0372 8.21197 17.5897 9.95864 18.4061L12.1749 19.4419C14.43 20.4957 15.206 20.8275 16 20.8275C16.794 20.8275 17.57 20.4957 19.8251 19.4419L22.0413 18.4061C23.788 17.5897 24.9623 17.0372 25.7173 16.5396C26.0863 16.2964 26.2668 16.1213 26.3505 16.01C26.3875 15.9608 26.3971 15.9351 26.3985 15.9309C26.3984 15.9312 26.3985 15.9308 26.3985 15.9309C26.3991 15.9295 26.4 15.9271 26.4 15.9177C26.4 15.9171 26.4 15.9164 26.4001 15.9157C26.4005 15.9109 26.4011 15.9041 26.3835 15.8743C26.3536 15.8233 26.2741 15.7175 26.0797 15.5587C25.5181 15.0999 25.4255 14.2609 25.8728 13.6849C26.3201 13.109 27.138 13.014 27.6996 13.4727C28.3971 14.0425 29 14.826 29 15.9177C29 17.2971 28.0512 18.1711 27.1229 18.7829C26.1903 19.3977 24.8407 20.0284 23.2184 20.7865L20.9032 21.8684C20.8191 21.9077 20.7363 21.9465 20.6544 21.9848C18.7492 22.8764 17.4291 23.4941 16 23.4941C14.5709 23.4941 13.2508 22.8764 11.3456 21.9848C11.2638 21.9465 11.1809 21.9077 11.0969 21.8684L8.78167 20.7865C7.15933 20.0284 5.80975 19.3977 4.87709 18.7829C3.94876 18.1711 3 17.2971 3 15.9177C3 14.826 3.60295 14.0425 4.30041 13.4727C4.86201 13.014 5.67989 13.109 6.12721 13.6849Z"
                                                            fill="#A7A7A7"
                                                        />
                                                    </svg>
                                                </span>
                                                {data.activePlan?.credits} Credits
                                            </h3>
                                        </div>
                                        <div className="custom-grid-single-box">
                                            <h4>Plan Amount</h4>
                                            <h3 className="m-0" style={{ color: "#000" }}>
                                                <span className="pe-2">
                                                    <svg
                                                        width="33"
                                                        height="32"
                                                        viewBox="0 0 33 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M6.3834 4.18327C4.69158 4.68535 3.66675 6.24133 3.66675 7.86819V20.1479C3.66672 21.4233 3.6667 22.4748 3.77824 23.3393C3.89631 24.2544 4.14712 25.0411 4.69739 25.7635C5.73227 27.1219 7.41999 27.5767 9.4584 28.126C13.1901 29.1343 17.0979 29.7313 20.584 30.0719C22.3018 30.2399 23.7894 30.3852 24.9258 30.318C26.1298 30.2469 27.1521 29.9421 28.0297 29.1401C28.9171 28.3292 29.3091 27.2961 29.4921 26.0548C29.6667 24.8692 29.6667 23.364 29.6667 21.5059V18.6733C29.6667 16.8615 29.6667 15.4077 29.5246 14.2657C29.3779 13.0882 29.065 12.0908 28.3239 11.2915C27.7463 10.6686 27.0014 10.2957 26.1415 10.0525C25.7607 9.94475 25.5703 9.89088 25.4885 9.76312C25.4066 9.63536 25.4363 9.44581 25.4957 9.06672C25.5954 8.42895 25.6626 7.7394 25.6666 7.0494C25.674 5.73585 25.4551 4.16623 24.5161 3.02171C23.2355 1.46104 21.3234 1.62856 20.2245 1.72484C13.7256 2.27672 8.98638 3.4108 6.3834 4.18327ZM20.3217 4.22561C20.9624 4.1712 21.4107 4.14189 21.7887 4.20181C22.1046 4.25189 22.3085 4.35512 22.4877 4.57363C22.8665 5.03517 23.0853 5.88587 23.0788 7.03573C23.0753 7.65707 23.0066 8.29271 22.9072 8.87505C22.853 9.19171 22.8259 9.35004 22.7042 9.44532C22.5823 9.54061 22.4145 9.52813 22.0789 9.5032C21.653 9.47156 21.2066 9.44064 20.7413 9.4084C15.1223 9.01904 10.3032 8.28363 7.16978 7.59881C6.78242 7.51415 6.58874 7.47183 6.53874 7.21321C6.48874 6.9546 6.60288 6.877 6.83115 6.72179C6.92907 6.65521 7.03474 6.6046 7.14419 6.57212C9.59456 5.84493 14.0246 4.76037 20.3217 4.22561ZM22.6667 17.3333C24.1395 17.3333 25.3334 18.5272 25.3334 20C25.3334 21.4728 24.1395 22.6667 22.6667 22.6667C21.194 22.6667 20.0001 21.4728 20.0001 20C20.0001 18.5272 21.194 17.3333 22.6667 17.3333Z"
                                                            fill="#A7A7A7"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="manrope-font">
                                                    <span className="small-custom-text">₹</span><b>{data.activePlan?.amount}</b><span className="small-custom-text">.00</span>
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="custom-grid-single-box">
                                            <h4>Last Purchased</h4>
                                            <h3 className="m-0" style={{ color: "#000" }}>
                                                <span className="pe-2">
                                                    <svg
                                                        width="33"
                                                        height="32"
                                                        viewBox="0 0 33 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.66691 2.66667C9.66691 1.93029 9.06995 1.33333 8.33358 1.33333C7.5972 1.33333 7.00024 1.93029 7.00024 2.66667V3.26513C7.50734 3.10428 8.04763 2.98944 8.62068 2.90615C8.95128 2.85809 9.29987 2.81968 9.66691 2.78897V2.66667Z"
                                                            fill="#A7A7A7"
                                                        />
                                                        <path
                                                            d="M25.6669 3.26513C25.1598 3.10428 24.6196 2.98944 24.0465 2.90615C23.7158 2.85809 23.3673 2.81968 23.0002 2.78897V2.66667C23.0002 1.93029 23.5972 1.33333 24.3336 1.33333C25.07 1.33333 25.6669 1.93029 25.6669 2.66667V3.26513Z"
                                                            fill="#A7A7A7"
                                                        />
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M17.7052 2.66667H14.9618C12.3641 2.66663 10.2687 2.66659 8.6206 2.90615C6.90063 3.15613 5.4759 3.69041 4.35752 4.89947C3.25282 6.09375 2.77722 7.59029 2.55238 9.39821C2.33346 11.1586 2.33347 13.4055 2.3335 16.2319V17.1015C2.33347 19.9279 2.33346 22.1748 2.55238 23.9351C2.77722 25.7431 3.25282 27.2396 4.35752 28.4339C5.4759 29.6429 6.90063 30.1772 8.6206 30.4272C10.2687 30.6668 12.3641 30.6667 14.9618 30.6667H17.7052C20.303 30.6667 22.3983 30.6668 24.0464 30.4272C25.7664 30.1772 27.1911 29.6429 28.3095 28.4339C29.4142 27.2396 29.8898 25.7431 30.1146 23.9351C30.3335 22.1748 30.3335 19.9279 30.3335 17.1015V16.2319C30.3335 13.4055 30.3335 11.1586 30.1146 9.39821C29.8898 7.59029 29.4142 6.09375 28.3095 4.89947C27.1911 3.69041 25.7664 3.15613 24.0464 2.90615C22.3983 2.66659 20.303 2.66663 17.7052 2.66667ZM6.3306 12C5.71911 12 5.41336 12 5.21863 12.1922C5.02391 12.3844 5.01999 12.6863 5.01216 13.29C5.00052 14.1876 5.00016 15.1923 5.00016 16.3243V17.0091C5.00016 19.9488 5.00259 22.0295 5.19866 23.606C5.39119 25.1541 5.75034 26.0125 6.31512 26.6231C6.86624 27.2189 7.61976 27.5871 9.00416 27.7883C10.4383 27.9967 12.3392 28 15.0668 28H17.6002C20.3278 28 22.2287 27.9967 23.6628 27.7883C25.0472 27.5871 25.8007 27.2189 26.3519 26.6231C26.9167 26.0125 27.2758 25.1541 27.4683 23.606C27.6644 22.0295 27.6668 19.9488 27.6668 17.0091V16.3243C27.6668 15.1923 27.6664 14.1876 27.6548 13.29C27.647 12.6863 27.6431 12.3844 27.4484 12.1922C27.2536 12 26.9479 12 26.3364 12H6.3306Z"
                                                            fill="#A7A7A7"
                                                        />
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M9.3335 17.3333C9.3335 16.4128 10.0797 15.6667 11.0002 15.6667H11.0121C11.9326 15.6667 12.6788 16.4128 12.6788 17.3333C12.6788 18.2539 11.9326 19 11.0121 19H11.0002C10.0797 19 9.3335 18.2539 9.3335 17.3333ZM14.6608 17.3333C14.6608 16.4128 15.4071 15.6667 16.3275 15.6667H16.3395C17.2599 15.6667 18.0062 16.4128 18.0062 17.3333C18.0062 18.2539 17.2599 19 16.3395 19H16.3275C15.4071 19 14.6608 18.2539 14.6608 17.3333ZM19.9882 17.3333C19.9882 16.4128 20.7344 15.6667 21.6548 15.6667H21.6668C22.5874 15.6667 23.3335 16.4128 23.3335 17.3333C23.3335 18.2539 22.5874 19 21.6668 19H21.6548C20.7344 19 19.9882 18.2539 19.9882 17.3333ZM9.3335 22.6667C9.3335 21.7461 10.0797 21 11.0002 21H11.0121C11.9326 21 12.6788 21.7461 12.6788 22.6667C12.6788 23.5872 11.9326 24.3333 11.0121 24.3333H11.0002C10.0797 24.3333 9.3335 23.5872 9.3335 22.6667ZM14.6608 22.6667C14.6608 21.7461 15.4071 21 16.3275 21H16.3395C17.2599 21 18.0062 21.7461 18.0062 22.6667C18.0062 23.5872 17.2599 24.3333 16.3395 24.3333H16.3275C15.4071 24.3333 14.6608 23.5872 14.6608 22.6667Z"
                                                            fill="#A7A7A7"
                                                        />
                                                    </svg>
                                                </span>
                                                {data.activePlan?.createdAt &&
                                                    new Date(data.activePlan.createdAt).toLocaleString(
                                                        "en-US",
                                                        {
                                                            month: "short",
                                                            day: "2-digit",
                                                            year: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            hour12: false,
                                                        }
                                                    )}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default AccountSetting;
