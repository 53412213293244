import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./component/Login";
import SignUp from "./component/SignUp";
import Dashboard from "./component/Merchant/Dashboard";
import AccountSetting from "./component/Merchant/AccountSetting";
import PaymentList from "./component/Merchant/PaymentList";
import ApiSetting from "./component/Merchant/ApiSetting";
import SubscriptionPlan from "./component/Merchant/SubscriptionPlan";
import AdminDashboard from "./component/Admin/Dashboard";
import AdminMerchants from "./component/Admin/Merchants";
import AdminPlans from "./component/Admin/Plans";
import AdminPayments from "./component/Admin/Payments";
import AdminGateways from "./component/Admin/Gateways";
import AdminSingleMerchants from "./component/Admin/SingleMerchants";
import MerchantPlan from "./component/Merchant/SingleMerchants";
import ContactUs from "./component/ContactUs";

function App() {
  const ProtectedRoute = ({ children, role, allowedRoles }) => {
    const userRole = atob(localStorage.getItem("role"));

    if (allowedRoles.includes(userRole)) {
      return children;
    } else {
      return <Navigate to="/login" replace />;
    }
  };
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp />} />

          {/* Merchant Routes */}
          <Route
            path="/Merchant/Dashboard"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Merchant/AccountSetting"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <AccountSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Merchant/PaymentList"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <PaymentList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Merchant/ApiSetting"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <ApiSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Merchant/SubscriptionPlan"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <SubscriptionPlan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Merchant/ContactUs"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <ContactUs />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/Merchant/Plans/:id"
            element={
              <ProtectedRoute allowedRoles={["merchant"]}>
                <MerchantPlan />
              </ProtectedRoute>
            }
          /> */}

          {/* Admin Routes */}
          <Route
            path="/Admin/Dashboard"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Admin/Merchants"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminMerchants />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Admin/Plans"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminPlans />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Admin/Payments"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminPayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Admin/Gateways"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminGateways />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Admin/SingleMerchants/:id"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminSingleMerchants />
              </ProtectedRoute>
            }
          />

          {/* Catch-all Route */}
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
