import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { React, useEffect, useMemo, useState } from "react";
import Sidebar from "../UI/Admin/SideNav";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { GateWay_Api, LogOutAuthForToeknInvalid } from "../Api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Merchants = () => {
  const [authToken, setauthToken] = useState(localStorage.getItem("authToken"));
  const [Alldata, setAlldata] = useState({});
  const [data, setdata] = useState([]);
  const [EditGateway, setEditGateway] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [sortOrderAmount, setSortOrderAmount] = useState('normal');

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredTableData = data.filter((item) =>
    Object.values(item).some((val) =>
      val ? val.toString().toLowerCase().includes(filterText.toLowerCase()) : false
    )
  );

  const sortedData = useMemo(() => {
    let sorted = [...filteredTableData];
    if (sortOrderAmount === 'ascending') {
      sorted.sort((a, b) => (a.amount || 0) - (b.amount || 0));
    } else if (sortOrderAmount === 'descending') {
      sorted.sort((a, b) => (b.amount || 0) - (a.amount || 0));
    }
    return sorted;
  }, [filteredTableData, sortOrderAmount]);

  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent invalid page change
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  const Get_User_Payment_Data = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: GateWay_Api,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAlldata(response.data.data);
        setdata(response.data.data.tableData.paymentGateways);
      })
      .catch((error) => {
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          LogOutAuthForToeknInvalid();
        }
      });
  };
  useEffect(() => {
    Get_User_Payment_Data();
  }, []);
  const handleDeleteClick = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${GateWay_Api}/${id}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          Get_User_Payment_Data();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          LogOutAuthForToeknInvalid();
        }
      });
  };

  const handleFormSubmit = (form, isEdit = false, modal) => {
    let formData = new FormData(form);
    let data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    let config = {
      method: isEdit ? "patch" : "post",
      maxBodyLength: Infinity,
      url: isEdit ? `${GateWay_Api}/${EditGateway.id}` : `${GateWay_Api}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          Get_User_Payment_Data();
          toast.success(response.data.message);
          form.reset();
          // if()
          console.log(modal);
          if (modal == "PaypalModal") {
            document.getElementById("PaypalModalCloseBtn").click();
          }
          if (modal == "RazorpayModal") {
            document.getElementById("RazorpayModalCloseBtn").click();
          }
          if (isEdit) {
            document.getElementById("EditModalCloseBtn").click();
          } else {
            document.getElementById("AddModalCloseBtn").click();
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          LogOutAuthForToeknInvalid();
        }
      });
  };

  const handleEditClick = (Gateway) => {
    setEditGateway(Gateway);
  };

  const changeLiveStatus = (event) => {
    let live = event.target.checked;
    let id = event.target.id;
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${GateWay_Api}/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        live: live,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          Get_User_Payment_Data();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          LogOutAuthForToeknInvalid();
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row min-vh-100">
          <Sidebar Active={"Gateways"} />
          <main className="col py-3 ms-auto custom-container-align">
            <div className="container">
              <div className="content-box-wrapper">
                <div
                  className="custom-grid-wrapper-box"
                  style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
                >
                  <div className="custom-grid-single-box">
                    <h4>Bank</h4>
                    <h3 className="m-0" style={{ color: "#000" }}>
                      <span className="pe-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.0002 1C11.2712 1 10.6011 1.18823 9.85974 1.51207C9.14074 1.82612 8.30696 2.29014 7.25824 2.87377L3.11608 5.17893C2.53967 5.49971 2.05726 5.90261 1.72587 6.44585C1.39281 6.99181 1.25016 7.61731 1.25016 8.31907L1.25008 8.38515C1.24962 8.64061 1.24915 8.89972 1.31072 9.13151C1.46568 9.7148 1.83495 10.0908 2.2872 10.2922C2.6946 10.4738 3.1372 10.5 3.46944 10.5H20.5309C20.8631 10.5 21.3057 10.4738 21.7131 10.2922C22.1654 10.0908 22.5346 9.7148 22.6896 9.13151C22.7512 8.89972 22.7507 8.64061 22.7502 8.38515V8.31907C22.7502 7.61731 22.6075 6.99181 22.2745 6.44585C21.9431 5.90261 21.4607 5.49971 20.8842 5.17893L16.7421 2.87379C15.6934 2.29014 14.8596 1.82612 14.1406 1.51206C13.3992 1.18823 12.7292 1 12.0002 1ZM11.9961 5.75C11.4438 5.75 10.9961 6.19772 10.9961 6.75C10.9961 7.30228 11.4438 7.75 11.9961 7.75H12.0051C12.5574 7.75 13.0051 7.30228 13.0051 6.75C13.0051 6.19772 12.5574 5.75 12.0051 5.75H11.9961Z"
                            fill="#A7A7A7"
                          />
                          <path
                            d="M1.25 21.5C1.25 19.8431 2.92893 18.5 5 18.5H19C21.0711 18.5 22.75 19.8431 22.75 21.5C22.75 22.0523 22.1904 22.5 21.5 22.5H2.5C1.80964 22.5 1.25 22.0523 1.25 21.5Z"
                            fill="#A7A7A7"
                          />
                          <path
                            d="M4.99984 17.25H8.35C8.53856 17.25 8.63285 17.25 8.69142 17.1914C8.75 17.1328 8.75 17.0386 8.75 16.85V12.15C8.75 11.9614 8.75 11.8672 8.69142 11.8086C8.63285 11.75 8.53856 11.75 8.35 11.75H3.46945C3.45328 11.75 3.4452 11.75 3.44041 11.7502C3.33507 11.7553 3.25529 11.8351 3.25023 11.9404C3.25 11.9452 3.25 11.9521 3.25 11.966V16.9883C3.25 17.2444 3.25 17.3724 3.32664 17.4325C3.40327 17.4926 3.53568 17.46 3.80049 17.3948C4.18472 17.3002 4.58642 17.25 4.99984 17.25Z"
                            fill="#A7A7A7"
                          />
                          <path
                            d="M18.9999 17.25C19.4134 17.25 19.8152 17.3002 20.1995 17.3948C20.4643 17.4601 20.5967 17.4927 20.6734 17.4326C20.75 17.3726 20.75 17.2445 20.75 16.9884V11.9657C20.75 11.9521 20.75 11.9453 20.7498 11.9406C20.7449 11.8351 20.6649 11.7552 20.5594 11.7502C20.5547 11.75 20.5468 11.75 20.5309 11.75H15.65C15.4615 11.75 15.3672 11.75 15.3086 11.8086C15.25 11.8672 15.25 11.9614 15.25 12.15V16.85C15.25 17.0386 15.25 17.1328 15.3086 17.1914C15.3672 17.25 15.4615 17.25 15.65 17.25H18.9999Z"
                            fill="#A7A7A7"
                          />
                        </svg>
                      </span>
                      ₹
                      <span>
                        <b>{Alldata.bank}</b>
                      </span>
                      .00{" "}
                    </h3>
                  </div>
                  <div className="custom-grid-single-box">
                    <h4>Cash</h4>
                    <h3 className="m-0" style={{ color: "#000" }}>
                      <span className="pe-2">
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6665 2.75C15.658 2.75 13.7963 3.14454 12.3491 3.82046C11.1313 4.38927 9.49215 4.75 7.6665 4.75C6.6545 4.75 5.69639 4.63907 4.83395 4.44153C3.5075 4.13772 1.9165 5.04678 1.9165 6.61397V17.386C1.9165 18.2216 2.04892 19.0082 2.48294 19.6488C2.93174 20.3111 3.62339 20.7031 4.49905 20.9037C5.47599 21.1274 6.54666 21.25 7.6665 21.25C9.67499 21.25 11.5367 20.8555 12.9839 20.1795C14.2017 19.6107 15.8409 19.25 17.6665 19.25C18.6785 19.25 19.6366 19.3609 20.4991 19.5585C21.8255 19.8623 23.4165 18.9532 23.4165 17.386V6.61397C23.4165 5.81712 23.3093 5.03453 22.8883 4.38745C22.449 3.71217 21.751 3.30637 20.8339 3.09633C19.857 2.87257 18.7863 2.75 17.6665 2.75Z"
                            fill="#A7A7A7"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.6665 12C15.6665 13.6569 14.3234 15 12.6665 15C11.0096 15 9.6665 13.6569 9.6665 12C9.6665 10.3431 11.0096 9 12.6665 9C14.3234 9 15.6665 10.3431 15.6665 12ZM6.71892 12.2171C7.13213 12.246 7.44367 12.6044 7.41477 13.0176C7.38587 13.4308 7.02748 13.7424 6.61427 13.7135C6.25503 13.6883 5.90277 13.6505 5.55911 13.6007C5.14917 13.5414 4.86498 13.1609 4.92434 12.751C4.9837 12.3411 5.36415 12.0569 5.77408 12.1162C6.08065 12.1606 6.3961 12.1946 6.71892 12.2171ZM19.7741 10.3992C20.184 10.4586 20.4682 10.839 20.4089 11.249C20.3495 11.6589 19.969 11.9431 19.5591 11.8838C19.2525 11.8394 18.9371 11.8054 18.6143 11.7829C18.2011 11.754 17.8895 11.3956 17.9184 10.9824C17.9473 10.5692 18.3057 10.2576 18.7189 10.2865C19.0782 10.3116 19.4304 10.3495 19.7741 10.3992Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      ₹
                      <span>
                        <b>{Alldata.cash}</b>
                      </span>
                      .00{" "}
                    </h3>
                  </div>
                  <div className="custom-grid-single-box">
                    <h4>Amount Collected</h4>
                    <h3 className="m-0" style={{ color: "#000" }}>
                      <span className="pe-2">
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.1685 6.21961C19.1574 5.80758 19.1266 5.4585 19.0412 5.14068C18.6797 3.79483 17.6261 2.7436 16.2771 2.38298C15.777 2.24931 15.1998 2.24963 14.4225 2.25005L4.51532 2.25009C2.89612 2.25009 1.5835 3.55965 1.5835 5.17508V13.0464C1.58347 14.8242 1.58345 16.2572 1.73533 17.3843C1.89302 18.5544 2.23037 19.5397 3.01468 20.3222C3.799 21.1046 4.78653 21.4412 5.9594 21.5985C7.08909 21.75 8.52546 21.75 10.3075 21.75H16.3115C17.6349 21.75 18.7262 21.7501 19.5898 21.6342C20.4966 21.5126 21.2974 21.2474 21.9386 20.6077C22.5798 19.968 22.8455 19.1691 22.9674 18.2645C23.0836 17.4028 23.0835 16.3141 23.0835 14.9938V12.9063C23.0835 11.586 23.0836 10.4973 22.9674 9.63562C22.8455 8.73099 22.5798 7.93206 21.9386 7.29235C21.2974 6.65265 20.4966 6.38748 19.5898 6.26586C19.4549 6.24776 19.3145 6.2325 19.1685 6.21961ZM14.2876 4.20007C15.2594 4.20007 15.5535 4.20845 15.7707 4.26652C16.4452 4.44683 16.9721 4.97244 17.1528 5.64537C17.184 5.76142 17.2009 5.89948 17.2098 6.15156C16.9232 6.15005 16.6236 6.15005 16.3111 6.15006H4.51487C3.97514 6.15006 3.5376 5.71354 3.5376 5.17507C3.5376 4.63659 3.97514 4.20007 4.51487 4.20007H14.2876Z"
                            fill="#A7A7A7"
                          />
                          <path
                            d="M19.8335 14C19.8335 12.8954 18.9381 12 17.8335 12C16.7289 12 15.8335 12.8954 15.8335 14C15.8335 15.1046 16.7289 16 17.8335 16C18.9381 16 19.8335 15.1046 19.8335 14Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      ₹
                      <span>
                        <b>{Alldata.total}</b>
                      </span>
                      .00{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="content-box-wrapper mt-3">
                <div className="d-flex mb-2">
                  <div className="custom-heading col">
                    <h2 className="d-flex align-items-center">
                      <span className="pe-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M19.4431 9.33337C21.0238 9.33335 22.3039 9.33333 23.3274 9.46197C24.3938 9.59601 25.3014 9.88148 26.0745 10.537C26.8462 11.1914 27.2809 12.0424 27.5971 13.0771C27.9014 14.0727 28.1261 15.3471 28.4042 16.9247L28.9163 19.8292C29.3029 22.0212 29.6115 23.7718 29.6598 25.1567C29.7097 26.5846 29.493 27.8091 28.6569 28.8206C27.8186 29.8346 26.6601 30.2687 25.2569 30.4711C23.8995 30.6667 22.1414 30.6667 19.9451 30.6667H12.0547C9.85842 30.6667 8.10031 30.6667 6.74299 30.4711C5.33975 30.2687 4.1812 29.8346 3.34295 28.8206C2.50679 27.8091 2.29019 26.5846 2.33996 25.1567C2.38824 23.7718 2.69694 22.0211 3.08347 19.8292L3.59558 16.9247C3.87374 15.3471 4.0984 14.0727 4.40272 13.0771C4.71896 12.0424 5.15362 11.1914 5.92536 10.537C6.69847 9.88148 7.60607 9.59601 8.6724 9.46197C9.69588 9.33333 10.976 9.33335 12.5567 9.33337H19.4431Z"
                            fill="#7F58EC"
                          />
                          <path
                            d="M15.9999 16.3333C17.5088 16.3333 18.5716 15.3045 18.6721 14.2112C18.7396 13.4778 19.3888 12.9381 20.1221 13.0056C20.8553 13.073 21.3951 13.7222 21.3276 14.4554C21.0815 17.1304 18.6661 19 15.9999 19C13.3337 19 10.9183 17.1304 10.6722 14.4554C10.6047 13.7222 11.1445 13.073 11.8778 13.0056C12.611 12.9381 13.2602 13.4778 13.3277 14.2112C13.4283 15.3045 14.4911 16.3333 15.9999 16.3333Z"
                            fill="#7F58EC"
                          />
                          <path
                            d="M11.7546 7.66987C11.9386 5.50135 13.7843 3.83336 15.9999 3.83336C18.2155 3.83336 20.0613 5.50135 20.2453 7.66987L20.3865 9.33449C21.3679 9.3382 22.2173 9.35396 22.9481 9.42103L22.7819 7.46225C22.4879 3.99801 19.5394 1.33337 15.9999 1.33337C12.4605 1.33337 9.51189 3.99801 9.21796 7.46225L9.05176 9.42103C9.7825 9.35396 10.6319 9.3382 11.6133 9.33449L11.7546 7.66987Z"
                            fill="#7F58EC"
                          />
                        </svg>
                      </span>
                      Payment Gateways
                    </h2>
                  </div>
                </div>
                <div className='table-srollablle table-height-100vh-284px'>
                  <table className="table table-2" style={{ borderCollapse: 'separate', borderSpacing: 0, width: '100%' }}>
                    <thead className="custom-thead-2" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <tr>
                        <th className="w-100-px">
                          <div className="table-heading-2 opacity-50">Order</div>
                        </th>
                        <th>
                          <div className="table-heading-2"
                          ><div className="text-start">
                              <p className="table-head-custom justify-content-start">
                                <span className="pe-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.3457 16.1976L16.1747 3.36866M18.6316 11.0556L16.4321 13.2551M14.5549 15.1099L13.5762 16.0886M4 22H20M3.17467 16.1411C1.60844 14.5749 1.60844 12.0355 3.17467 10.4693L10.4693 3.17467C12.0355 1.60844 14.5749 1.60844 16.1411 3.17467L20.8253 7.85891C22.3916 9.42514 22.3916 11.9645 20.8253 13.5307L13.5307 20.8253C11.9645 22.3916 9.42514 22.3916 7.85891 20.8253L3.17467 16.1411Z"
                                      stroke="black"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </span>
                                Payment Gateways
                              </p>
                            </div>
                          </div>
                        </th>
                        <th className="w-100-px">
                          <div className="table-heading-2">

                            <p className="table-head-custom">
                              <span className="pe-2">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.9532 9.20416C18.2065 9.55941 18.3332 9.73707 18.3332 9.99999C18.3332 10.2629 18.2065 10.4406 17.9532 10.7958C16.8148 12.3922 13.9075 15.8333 9.99984 15.8333C6.09215 15.8333 3.18492 12.3922 2.04654 10.7958C1.79318 10.4406 1.6665 10.2629 1.6665 9.99999C1.6665 9.73707 1.79318 9.55941 2.04654 9.20416C3.18492 7.60786 6.09215 4.16666 9.99984 4.16666C13.9075 4.16666 16.8148 7.60786 17.9532 9.20416Z"
                                    stroke="black"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M12.5 10C12.5 8.61925 11.3807 7.5 10 7.5C8.61925 7.5 7.5 8.61925 7.5 10C7.5 11.3807 8.61925 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10Z"
                                    stroke="black"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              </span>
                              Live
                            </p>
                          </div>
                        </th>
                        <th className="w-100-px" style={{ width: '150px' }}>
                          <div className="table-heading-2">
                            <p className="table-head-custom">
                              <span className="pe-2">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_104_1182)">
                                    <path
                                      d="M9.99658 10H10.0041"
                                      stroke="black"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13.333 10H13.3405"
                                      stroke="black"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.6665 10H6.67399"
                                      stroke="black"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M18.3332 9.99999C18.3332 5.39761 14.6022 1.66666 9.99984 1.66666C5.39746 1.66666 1.6665 5.39761 1.6665 9.99999C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99999Z"
                                      stroke="black"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_104_1182">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Action
                            </p>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="custom-tbody" style={{ background: '#fff' }}>
                      {currentRows.length > 0 ? (
                        currentRows.map((row, index) => (
                          <tr key={row.id}>
                            <td className="text-center w-100-px">
                              <span className="px-2">
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g opacity="0.6">
                                    <path d="M3.5 7.49998C3.5 7.49998 5.75 4.5 6.5 4.5C7.25 4.5 9.5 7.5 9.5 7.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                </svg>
                              </span>
                              {row.sortOrder}
                              <span className="px-2">
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g opacity="0.6">
                                    <path d="M9.5 4.50003C9.5 4.50003 7.25 7.5 6.5 7.5C5.75 7.5 3.5 4.5 3.5 4.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                </svg>
                              </span>
                            </td>
                            <td className="text-start">{row.name}</td>
                            <td className="text-center w-100-px">
                              <div class="form-check form-switch d-flex align-items-center justify-content-center">
                                <input
                                  class="form-check-input custom-check-box"
                                  type="checkbox"
                                  role="switch"
                                  checked={row.live}
                                  id={row.id}
                                  onChange={changeLiveStatus}
                                />
                              </div>
                            </td>
                            <td className="text-center" style={{ width: '100px' }}>
                              <button
                                className="download-btn"
                                data-bs-toggle="modal"
                                data-bs-target={
                                  row.name == "Razorpay"
                                    ? "#RazorpayModal"
                                    : "#PaypalModal"
                                }
                                onClick={() => handleEditClick(row)}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.2141 4.98239L17.6158 3.58063C18.39 2.80646 19.6452 2.80646 20.4194 3.58063C21.1935 4.3548 21.1935 5.60998 20.4194 6.38415L19.0176 7.78591M16.2141 4.98239L10.9802 10.2163C9.93493 11.2616 9.41226 11.7842 9.05637 12.4211C8.70047 13.058 8.3424 14.5619 8 16C9.43809 15.6576 10.942 15.2995 11.5789 14.9436C12.2158 14.5877 12.7384 14.0651 13.7837 13.0198L19.0176 7.78591M16.2141 4.98239L19.0176 7.78591"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M21 12C21 16.2426 21 18.364 19.682 19.682C18.364 21 16.2426 21 12 21C7.75736 21 5.63604 21 4.31802 19.682C3 18.364 3 16.2426 3 12C3 7.75736 3 5.63604 4.31802 4.31802C5.63604 3 7.75736 3 12 3"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </button>
                              <button
                                className="download-btn ms-2"
                                data-bs-toggle="modal"
                                data-bs-target="#DeletePlanModal"
                                onClick={() => handleDeleteClick(row.id)}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5"
                                    stroke="#F00D0D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5"
                                    stroke="#F00D0D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M9.5 16.5V10.5"
                                    stroke="#F00D0D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M14.5 16.5V10.5"
                                    stroke="#F00D0D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-center table-pagination-box" style={{ position: 'sticky', bottom: 0, zIndex: 1 }}>
                    <ul className="custom-pagination p-0">
                      <li>
                        Page {currentPage} of {totalPages}
                      </li>
                      <select
                        className="light-custom-box"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                      </select>
                    </ul>
                    <nav>
                      <ul className="custom-pagination">
                        <li className="page-item" onClick={() => handlePageChange(currentPage - 1)}>
                          <button
                            className="page-link"
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPages).keys()].map((page) => (
                          <li
                            key={page + 1}
                            className={`page-item ${page + 1 === currentPage ? "active" : ""}`}
                            onClick={() => handlePageChange(page + 1)}
                          >
                            <button
                              className="page-link"
                            >
                              {page + 1}
                            </button>
                          </li>
                        ))}
                        <li className="page-item" onClick={() => handlePageChange(currentPage + 1)}>
                          <button
                            className="page-link"
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Razorpay Modal */}
      <div
        class="modal fade"
        id="RazorpayModal"
        tabindex="-1"
        aria-labelledby="RazorpayModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit(e.target, true, "RazorpayModal");
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="custom-btn-light-with-box-shadow me-3"
                  data-bs-dismiss="modal"
                >
                  <span>
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3335 5H14.6668"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.50013 9.16659C5.50013 9.16659 1.3335 6.09792 1.3335 4.99992C1.33349 3.90192 5.50016 0.833252 5.50016 0.833252"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
                <h5 class="modal-title" id="RazorpayModalLabel">
                  <span>
                    <img
                      src="/assets/img/Razorpay.png"
                      alt=""
                      style={{ maxWidth: "35px" }}
                    />
                  </span>{" "}
                  Edit Razorpay Payment Gateway
                </h5>
              </div>
              <div class="modal-body">
                <div class="form-wrapper p-0 shadow-sm overflow-hidden">
                  <table class="table table-borderless custom-table mb-0">
                    <tbody>
                      <tr>
                        <td className="ps-3">
                          <div className="opacity-50 d-inline-block">Key</div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="w-100 makeInputStyleNone"
                            placeholder="rzp_test_1234567890abcdef"
                            value={EditGateway.key}
                            onChange={(e) =>
                              setEditGateway({
                                ...EditGateway,
                                key: e.target.value,
                              })
                            }
                            name="key"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-3">
                          <div className="opacity-50 d-inline-block">
                            Secret Key
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="w-100 makeInputStyleNone"
                            placeholder="1234567890abcdef1234567890abcdef"
                            value={EditGateway.secretKey}
                            onChange={(e) =>
                              setEditGateway({
                                ...EditGateway,
                                secretKey: e.target.value,
                              })
                            }
                            name="secretKey"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer d-flex">
                <button
                  type="button"
                  class="simple-light-btn"
                  data-bs-dismiss="modal"
                  id="RazorpayModalCloseBtn"
                >
                  Cancel
                </button>
                <button type="submit" class="simple-dark-btn col">
                  Save Edits
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Paypal Modal */}
      <div
        class="modal fade"
        id="PaypalModal"
        tabindex="-1"
        aria-labelledby="PaypalModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit(e.target, true, "PaypalModal");
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="custom-btn-light-with-box-shadow me-3"
                  data-bs-dismiss="modal"
                >
                  <span>
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.3335 5H14.6668"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.50013 9.16659C5.50013 9.16659 1.3335 6.09792 1.3335 4.99992C1.33349 3.90192 5.50016 0.833252 5.50016 0.833252"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
                <h5 class="modal-title" id="PaypalModalLabel">
                  <span>
                    <img
                      src="/assets/img/Paypal.png"
                      alt=""
                      style={{ maxWidth: "35px" }}
                    />
                  </span>{" "}
                  Edit Paypal Payment Gateway
                </h5>
              </div>
              <div class="modal-body">
                <div class="form-wrapper p-0 shadow-sm overflow-hidden">
                  <table class="table table-borderless custom-table mb-0">
                    <tbody>
                      <tr>
                        <td className="ps-3">
                          <div className="opacity-50 d-inline-block">
                            Client ID
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="w-100 makeInputStyleNone"
                            placeholder="AeF1234567890abcdef1234567890abcdef1234567890abcdef"
                            value={EditGateway.key}
                            onChange={(e) =>
                              setEditGateway({
                                ...EditGateway,
                                key: e.target.value,
                              })
                            }
                            name="key"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-3">
                          <div className="opacity-50 d-inline-block">
                            Client Secret
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="w-100 makeInputStyleNone"
                            placeholder="EFGHIJKLMNOPQRSTUVWXYZ1234567890abcdef"
                            value={EditGateway.secretKey}
                            onChange={(e) =>
                              setEditGateway({
                                ...EditGateway,
                                secretKey: e.target.value,
                              })
                            }
                            name="secretKey"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer d-flex">
                <button
                  type="button"
                  class="simple-light-btn"
                  data-bs-dismiss="modal"
                  id="PaypalModalCloseBtn"
                >
                  Cancel
                </button>
                <button type="submit" class="simple-dark-btn col">
                  Save Edits
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Merchants;
