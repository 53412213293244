import React, { useRef } from "react";
import { Register_Api } from "./Api";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

const SignUp = () => {
    const formRef = useRef(null);

    const validateForm = (event) => {
        event.preventDefault();
        const form = event.target;
        const mobileNumber = form.mobileNumber.value;
        const name = form.name.value;
        const password = form.password.value;

        if (!name) {
            toast.error("Please Enter Your Name");
            return;
        }
        if (!mobileNumber || mobileNumber.length !== 10) {
            toast.error("Please Enter a Valid Mobile Number");
            return;
        }
        if (!password) {
            toast.error("Please Enter Your Password");
            return;
        }

        let data = {
            mobileNumber: '+91' + mobileNumber,
            name: name,
            password: password,
            company: form.company.value,
            gst: form.gst.value,
            email: form.email.value,
        };

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${Register_Api}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios.request(config)
            .then((response) => {
                if (response.data.status == 'success') {
                    toast.success(response.data.message);
                    window.location.href = '/login';
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <>
            <ToastContainer />
            {/* Sign Up Form */}
            <div className="d-flex login-container p-16">
                <div className="login-form-main signup-form-main login-form-content-wrapper d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="col-12">
                        <img className="Login-form-logo mb-3" src="/assets/img/logo.png" />
                        <h2 className="signup-title">Sign Up to ApiAdmin</h2>
                        <h4>Please Provide Your Details</h4>
                    </div>

                    <form className="col-10 col-md-8 text-start d-flex flex-wrap custom-form py-4" ref={formRef} onSubmit={validateForm}>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>Full Name</label>
                            <div className="input-group newerror">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="black" strokeWidth="1.25" />
                                    </svg>
                                </span>
                                <input type="text" name="name" className="form-control custom-input" placeholder="Enter Your Full Name" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>Company</label>
                            <div className="input-group">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 22V8C14 5.17157 14 3.75736 13.1213 2.87868C12.2426 2 10.8284 2 8 2C5.17157 2 3.75736 2 2.87868 2.87868C2 3.75736 2 5.17157 2 8V16C2 18.8284 2 20.2426 2.87868 21.1213C3.75736 22 5.17157 22 8 22H14Z" stroke="black" strokeWidth="1.25" strokeLinecap="round" />
                                        <path d="M6.5 11H5.5M10.5 11H9.5M6.5 7H5.5M6.5 15H5.5M10.5 7H9.5M10.5 15H9.5" stroke="black" strokeWidth="1.25" strokeLinecap="round" />
                                        <path d="M18.5 15H17.5M18.5 11H17.5" stroke="black" strokeWidth="1.25" strokeLinecap="round" />
                                        <path d="M18 8H14V22H18C19.8856 22 20.8284 22 21.4142 21.4142C22 20.8284 22 19.8856 22 18V12C22 10.1144 22 9.17157 21.4142 8.58579C20.8284 8 19.8856 8 18 8Z" stroke="black" strokeWidth="1.25" strokeLinecap="round" />
                                    </svg>
                                </span>
                                <input type="text" name="company" className="form-control custom-input" placeholder="Enter Your Company Name" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>WhatsApp Number</label>
                            <div className="input-group newerror">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 12.3789 1.27907 13.6926 1.78382 14.8877C2.06278 15.5481 2.20226 15.8784 2.21953 16.128C2.2368 16.3776 2.16334 16.6521 2.01642 17.2012L1 21L4.79877 19.9836C5.34788 19.8367 5.62244 19.7632 5.87202 19.7805C6.12161 19.7977 6.45185 19.9372 7.11235 20.2162C8.30745 20.7209 9.6211 21 11 21Z" stroke="black" strokeWidth="1.25" strokeLinejoin="round" />
                                        <path d="M7.58815 11.3773L8.45909 10.2956C8.82616 9.8397 9.2799 9.4153 9.3155 8.80826C9.3244 8.65494 9.2166 7.96657 9.0008 6.58986C8.91601 6.04881 8.41086 6 7.97332 6C7.40314 6 7.11805 6 6.83495 6.12931C6.47714 6.29275 6.10979 6.75231 6.02917 7.13733C5.96539 7.44196 6.01279 7.65187 6.10759 8.07169C6.51023 9.8548 7.45481 11.6158 8.91948 13.0805C10.3842 14.5452 12.1452 15.4898 13.9283 15.8924C14.3481 15.9872 14.558 16.0346 14.8627 15.9708C15.2477 15.8902 15.7072 15.5229 15.8707 15.165C16 14.8819 16 14.5969 16 14.0267C16 13.5891 15.9512 13.084 15.4101 12.9992C14.0334 12.7834 13.3451 12.6756 13.1917 12.6845C12.5847 12.7201 12.1603 13.1738 11.7044 13.5409L10.6227 14.4118" stroke="black" strokeWidth="1.25" />
                                    </svg>
                                </span>
                                <input type="text" name="mobileNumber" className="form-control custom-input" placeholder="Enter Your WhatsApp Number" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>GST Number</label>
                            <div className="input-group">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.4407 21.3478L16.9929 20.6576C17.4781 20.0511 18.5085 20.1 18.9184 20.7488C19.4114 21.5295 20.6292 21.3743 20.9669 20.6562C20.9845 20.6189 20.9405 20.7123 20.9781 20.3892C21.0156 20.0661 21 19.9923 20.9687 19.8448L19.0456 10.7641C18.5623 8.48195 18.3206 7.34086 17.4893 6.67043C16.6581 6 15.4848 6 13.1384 6H10.8616C8.51517 6 7.34194 6 6.51066 6.67043C5.67937 7.34086 5.43771 8.48195 4.95439 10.7641L3.0313 19.8448C3.00004 19.9923 2.98441 20.0661 3.02194 20.3892C3.05946 20.7123 3.01554 20.6189 3.0331 20.6562C3.37084 21.3743 4.58856 21.5295 5.08165 20.7488C5.49152 20.1 6.52187 20.0511 7.00709 20.6576L7.55934 21.3478C8.25514 22.2174 9.70819 22.2174 10.404 21.3478L10.4908 21.2392C11.2291 20.3165 12.7709 20.3165 13.5092 21.2392L13.596 21.3478C14.2918 22.2174 15.7449 22.2174 16.4407 21.3478Z" stroke="black" strokeWidth="1.25" strokeLinejoin="round" />
                                        <path d="M2.48336 9.5C1.89805 8.89199 2.00824 8.10893 2.00824 6.15176C2.00824 4.1946 2.00824 3.21602 2.59355 2.60801C3.17886 2 4.1209 2 6.00497 2H17.9952C19.8792 2 20.8213 2 21.4066 2.60801C21.9919 3.21602 21.9919 4.1946 21.9919 6.15176C21.9919 8.10893 22.1014 8.89199 21.5161 9.5" stroke="black" strokeWidth="1.25" strokeLinecap="round" />
                                        <path d="M12 10H9" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14 14H8" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <input type="text" name="gst" className="form-control custom-input" placeholder="Enter Your GST Number" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>Email Address</label>
                            <div className="input-group">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="16" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 6.5L8.94202 8.2394C10.6572 9.2535 11.3428 9.2535 13.058 8.2394L16 6.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M1.01577 11.4756C1.08114 14.5411 1.11383 16.0739 2.24496 17.2093C3.37609 18.3448 4.95034 18.3843 8.09884 18.4634C10.0393 18.5122 11.9607 18.5122 13.9012 18.4634C17.0497 18.3843 18.6239 18.3448 19.755 17.2093C20.8862 16.0739 20.9189 14.5411 20.9842 11.4756C21.0053 10.4899 21.0053 9.51003 20.9842 8.52443C20.9189 5.45886 20.8862 3.92609 19.755 2.79066C18.6239 1.65523 17.0497 1.61568 13.9012 1.53657C11.9607 1.48781 10.0393 1.48781 8.09882 1.53656C4.95034 1.61566 3.37609 1.65521 2.24496 2.79065C1.11383 3.92608 1.08114 5.45885 1.01577 8.52433C0.994745 9.51003 0.994745 10.4899 1.01577 11.4756Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <input type="text" name="email" className="form-control custom-input" placeholder="Enter Your Email Address" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 mb-3">
                            <label>Password</label>
                            <div className="input-group newerror">
                                <span className="input-group-text custom-input-group-text" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.491 15.5H14.5M9.5 15.5H9.50897" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12105 13.2453 4 14.3624 4 15.5C4 16.6376 4.12105 17.7547 4.26781 18.8447Z" stroke="black" strokeWidth="1.5" />
                                        <path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <input type="password" name="password" className="form-control custom-input" placeholder="Enter Your Password" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-12 px-2" style={{ marginTop: '26px' }}>
                            <button className="btn custom-btn w-100">Sign up
                                <span className="ps-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 18V6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M16 11.9995H4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 8C12 8 16 10.946 16 12C16 13.0541 12 16 12 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </form>

                    <div className="login-link">
                        <p className="secoundary-color">Already have an account? <a href="/login">Login Now</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUp;