import React from 'react';

const Sidebar = (props) => {
    let Active = props.Active;
    return (
        <>
            <aside className="col-md-4 col-lg-3 pe-0" style={{ position: 'fixed' }}>
                <div className="vh-100 py-3">
                    <div className="sidebar sidebar-admin custom-light-gray h-100 d-flex justify-content-between" style={{ flexDirection: 'column' }}>
                        <div>
                            <div className="text-center p-3">
                                <img src="/assets/img/SuperAdminLogo.png" alt="ApiAdmin Logo" className="img-fluid mb-2" style={{ width: '200px' }} />
                            </div>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className={`nav-link ${Active == 'Dashboard' ? 'active' : ''}`} href="/Admin/Dashboard" >
                                        <span>
                                            {Active == 'Dashboard' ?
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25Z" fill="white" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3213 6.32465L11.4014 7.29796C9.8912 7.80136 9.1361 8.05306 8.59457 8.59457C8.05306 9.1361 7.80136 9.8912 7.29796 11.4014L6.32465 14.3213C6.02924 15.2075 5.88153 15.6507 6.11544 15.8846C6.34935 16.1185 6.79247 15.9708 7.67871 15.6753L10.5986 14.702C12.1088 14.1986 12.8639 13.9469 13.4054 13.4054C13.9469 12.8639 14.1986 12.1088 14.702 10.5986L15.6754 7.6787C15.9708 6.79246 16.1185 6.34935 15.8846 6.11544C15.6507 5.88153 15.2075 6.02924 14.3213 6.32465ZM11.708 11.7017C12.0973 11.3124 12.0973 10.6813 11.708 10.292C11.3187 9.9027 10.6876 9.9027 10.2983 10.292L10.292 10.2983C9.9027 10.6876 9.9027 11.3187 10.292 11.708C10.6813 12.0973 11.3124 12.0973 11.7017 11.708L11.708 11.7017Z" fill="white" />
                                                </svg>
                                                :
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3213 6.32465L11.4014 7.29796C9.8912 7.80136 9.1361 8.05306 8.59457 8.59457C8.05306 9.1361 7.80136 9.8912 7.29796 11.4014L6.32465 14.3213C6.02924 15.2075 5.88153 15.6507 6.11544 15.8846C6.34935 16.1185 6.79247 15.9708 7.67871 15.6753L10.5986 14.702C12.1088 14.1986 12.8639 13.9469 13.4054 13.4054C13.9469 12.8639 14.1986 12.1088 14.702 10.5986L15.6754 7.6787C15.9708 6.79246 16.1185 6.34935 15.8846 6.11544C15.6507 5.88153 15.2075 6.02924 14.3213 6.32465ZM11.708 11.7017C12.0973 11.3124 12.0973 10.6813 11.708 10.292C11.3187 9.9027 10.6876 9.9027 10.2983 10.292L10.292 10.2983C9.9027 10.6876 9.9027 11.3187 10.292 11.708C10.6813 12.0973 11.3124 12.0973 11.7017 11.708L11.708 11.7017Z" fill="#A7A7A7" />
                                                </svg>}
                                        </span> Dashboard
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${Active == 'Merchants' ? 'active' : ''}`} href="/Admin/Merchants">
                                        <span>
                                            {Active == 'Merchants' ?
                                                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.4">
                                                        <path d="M14.3207 8.9875C14.1266 9.2612 14.0295 9.3981 14.0663 9.5336C14.1031 9.6691 14.2324 9.731 14.4911 9.8548C15.0294 10.1125 15.6365 10.2574 16.2788 10.2574C18.4944 10.2574 20.2906 8.5331 20.2906 6.40604C20.2906 4.279 18.4944 2.55469 16.2788 2.55469C16.0372 2.55469 15.8006 2.57519 15.5707 2.61449C15.2861 2.66315 15.1438 2.68748 15.0687 2.80762C14.9935 2.92776 15.0467 3.08555 15.153 3.40113C15.363 4.02415 15.4765 4.68886 15.4765 5.37901C15.4765 6.71691 15.0501 7.9592 14.3207 8.9875Z" fill="white" />
                                                        <path d="M19.1503 18.4736C20.5967 18.4736 21.7217 17.8805 22.6571 17.1401C23.2115 16.7013 23.5749 16.1362 23.4871 15.4695C23.4083 14.8708 22.9868 14.4251 22.6231 14.1294C22.0272 13.6448 21.1579 13.238 20.6102 12.9816C20.4892 12.925 20.3839 12.8757 20.3007 12.8345C19.2334 12.3065 18.0592 11.9802 16.8597 11.8557C16.0862 11.7753 15.6994 11.7352 15.611 11.9569C15.5226 12.1787 15.8671 12.4163 16.5559 12.8914C16.8175 13.0719 17.0705 13.2652 17.2926 13.4665C17.7926 13.9195 18.5256 14.7348 18.6638 15.9053C18.7279 16.4478 18.6537 16.9475 18.4869 17.399C18.2883 17.9367 18.1889 18.2056 18.2748 18.3329C18.2803 18.3411 18.2837 18.3456 18.2898 18.3534C18.3862 18.4736 18.6409 18.4736 19.1503 18.4736Z" fill="white" />
                                                    </g>
                                                    <path d="M14.0401 13.2192C14.631 13.5275 15.5579 14.0112 16.192 14.5857C16.5868 14.9434 16.9951 15.4428 17.0701 16.0777C17.1512 16.7648 16.8217 17.3852 16.2295 17.9074C15.2357 18.784 14.0272 19.4996 12.4598 19.4996H5.12247C3.55506 19.4996 2.34661 18.784 1.35274 17.9074C0.760633 17.3852 0.431036 16.7648 0.512166 16.0777C0.587143 15.4428 0.995532 14.9434 1.39032 14.5857C2.02438 14.0112 2.95118 13.5276 3.54205 13.2193C3.67361 13.1507 3.78851 13.0907 3.87983 13.0404C6.88769 11.3824 10.6946 11.3824 13.7025 13.0404C13.7937 13.0907 13.9086 13.1506 14.0401 13.2192Z" fill="white" />
                                                    <path d="M3.70898 5.37838C3.70898 2.68412 5.98406 0.5 8.79051 0.5C11.597 0.5 13.872 2.68412 13.872 5.37838C13.872 8.0726 11.597 10.2568 8.79051 10.2568C5.98406 10.2568 3.70898 8.0726 3.70898 5.37838Z" fill="white" />
                                                </svg>
                                                :
                                                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.4">
                                                        <path d="M14.3207 8.9875C14.1266 9.2612 14.0295 9.3981 14.0663 9.5336C14.1031 9.6691 14.2324 9.731 14.4911 9.8548C15.0294 10.1125 15.6365 10.2574 16.2788 10.2574C18.4944 10.2574 20.2906 8.5331 20.2906 6.40604C20.2906 4.279 18.4944 2.55469 16.2788 2.55469C16.0372 2.55469 15.8006 2.57519 15.5707 2.61449C15.2861 2.66315 15.1438 2.68748 15.0687 2.80762C14.9935 2.92776 15.0467 3.08555 15.153 3.40113C15.363 4.02415 15.4765 4.68886 15.4765 5.37901C15.4765 6.71691 15.0501 7.9592 14.3207 8.9875Z" fill="#A7A7A7" />
                                                        <path d="M19.1503 18.4736C20.5967 18.4736 21.7217 17.8805 22.6571 17.1401C23.2115 16.7013 23.5749 16.1362 23.4871 15.4695C23.4083 14.8708 22.9868 14.4251 22.6231 14.1294C22.0272 13.6448 21.1579 13.238 20.6102 12.9816C20.4892 12.925 20.3839 12.8757 20.3007 12.8345C19.2334 12.3065 18.0592 11.9802 16.8597 11.8557C16.0862 11.7753 15.6994 11.7352 15.611 11.9569C15.5226 12.1787 15.8671 12.4163 16.5559 12.8914C16.8175 13.0719 17.0705 13.2652 17.2926 13.4665C17.7926 13.9195 18.5256 14.7348 18.6638 15.9053C18.7279 16.4478 18.6537 16.9475 18.4869 17.399C18.2883 17.9367 18.1889 18.2056 18.2748 18.3329C18.2803 18.3411 18.2837 18.3456 18.2898 18.3534C18.3862 18.4736 18.6409 18.4736 19.1503 18.4736Z" fill="#A7A7A7" />
                                                    </g>
                                                    <path d="M14.0401 13.2192C14.631 13.5275 15.5579 14.0112 16.192 14.5857C16.5868 14.9434 16.9951 15.4428 17.0701 16.0777C17.1512 16.7648 16.8217 17.3852 16.2295 17.9074C15.2357 18.784 14.0272 19.4996 12.4598 19.4996H5.12247C3.55506 19.4996 2.34661 18.784 1.35274 17.9074C0.760633 17.3852 0.431036 16.7648 0.512166 16.0777C0.587143 15.4428 0.995532 14.9434 1.39032 14.5857C2.02438 14.0112 2.95118 13.5276 3.54205 13.2193C3.67361 13.1507 3.78851 13.0907 3.87983 13.0404C6.88769 11.3824 10.6946 11.3824 13.7025 13.0404C13.7937 13.0907 13.9086 13.1506 14.0401 13.2192Z" fill="#A7A7A7" />
                                                    <path d="M3.70898 5.37838C3.70898 2.68412 5.98406 0.5 8.79051 0.5C11.597 0.5 13.872 2.68412 13.872 5.37838C13.872 8.0726 11.597 10.2568 8.79051 10.2568C5.98406 10.2568 3.70898 8.0726 3.70898 5.37838Z" fill="#A7A7A7" />
                                                </svg>
                                            }
                                        </span> Merchants
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${Active == 'Plans' ? 'active' : ''}`} href="/Admin/Plans">
                                        <span>
                                            {Active == 'Plans' ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M2 8.75C2.55229 8.75 3 9.1977 3 9.75V14.25C3 15.6925 3.00213 16.6737 3.10092 17.4086C3.19585 18.1146 3.36322 18.4416 3.58579 18.6642C3.80836 18.8868 4.13538 19.0542 4.84143 19.1491C5.57625 19.2479 6.55752 19.25 8 19.25H14C15.4425 19.25 16.4238 19.2479 17.1586 19.1491C17.8646 19.0542 18.1916 18.8868 18.4142 18.6642C18.6368 18.4416 18.8042 18.1146 18.8991 17.4086C18.9979 16.6737 19 15.6925 19 14.25V9.75C19 9.1977 19.4477 8.75 20 8.75C20.5523 8.75 21 9.1977 21 9.75V14.3205C21 15.6747 21.0001 16.7913 20.8813 17.6751C20.7565 18.6029 20.4845 19.4223 19.8284 20.0784C19.1723 20.7345 18.3529 21.0065 17.4251 21.1312C16.5413 21.2501 15.4247 21.25 14.0706 21.25H7.92943C6.57531 21.25 5.4587 21.2501 4.57494 21.1312C3.64711 21.0065 2.82768 20.7345 2.17158 20.0784C1.51547 19.4223 1.2435 18.6029 1.11875 17.6751C0.999941 16.7913 0.999971 15.6747 1 14.3206V9.75C1 9.1977 1.44772 8.75 2 8.75Z" fill="white" />
                                                <path d="M2.19143 3.45934C2.19143 1.95786 3.41603 0.75 4.91512 0.75H17.0849C18.584 0.75 19.8086 1.95786 19.8086 3.45934C19.8086 4.00972 19.9532 4.55089 20.2287 5.02939L21.2149 6.74274C21.4737 7.19195 21.6839 7.55669 21.7347 8.16669C21.7553 8.41456 21.7576 8.62312 21.726 8.82441C21.6958 9.0172 21.6381 9.1717 21.5956 9.2854L21.5894 9.3023C21.0565 10.7329 19.6723 11.75 18.0513 11.75C16.695 11.75 15.5023 11.037 14.8374 9.9644C13.9338 11.0575 12.5446 11.75 11 11.75C9.4554 11.75 8.06617 11.0575 7.16259 9.9644C6.49773 11.037 5.30506 11.75 3.94875 11.75C2.32768 11.75 0.943552 10.7329 0.410652 9.3022L0.404362 9.2854C0.361902 9.1717 0.304212 9.0172 0.273972 8.82441C0.242402 8.62312 0.244692 8.41457 0.265332 8.16669C0.316132 7.55668 0.526282 7.19195 0.785092 6.74274L1.77133 5.02939C2.04677 4.55089 2.19143 4.00972 2.19143 3.45934Z" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16.5C5 15.9477 5.44772 15.5 6 15.5H10C10.5523 15.5 11 15.9477 11 16.5C11 17.0523 10.5523 17.5 10 17.5H6C5.44772 17.5 5 17.0523 5 16.5Z" fill="white" />
                                            </svg>
                                                :
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M2 8.75C2.55229 8.75 3 9.1977 3 9.75V14.25C3 15.6925 3.00213 16.6737 3.10092 17.4086C3.19585 18.1146 3.36322 18.4416 3.58579 18.6642C3.80836 18.8868 4.13538 19.0542 4.84143 19.1491C5.57625 19.2479 6.55752 19.25 8 19.25H14C15.4425 19.25 16.4238 19.2479 17.1586 19.1491C17.8646 19.0542 18.1916 18.8868 18.4142 18.6642C18.6368 18.4416 18.8042 18.1146 18.8991 17.4086C18.9979 16.6737 19 15.6925 19 14.25V9.75C19 9.1977 19.4477 8.75 20 8.75C20.5523 8.75 21 9.1977 21 9.75V14.3205C21 15.6747 21.0001 16.7913 20.8813 17.6751C20.7565 18.6029 20.4845 19.4223 19.8284 20.0784C19.1723 20.7345 18.3529 21.0065 17.4251 21.1312C16.5413 21.2501 15.4247 21.25 14.0706 21.25H7.92943C6.57531 21.25 5.4587 21.2501 4.57494 21.1312C3.64711 21.0065 2.82768 20.7345 2.17158 20.0784C1.51547 19.4223 1.2435 18.6029 1.11875 17.6751C0.999941 16.7913 0.999971 15.6747 1 14.3206V9.75C1 9.1977 1.44772 8.75 2 8.75Z" fill="#A7A7A7" />
                                                    <path d="M2.19143 3.45934C2.19143 1.95786 3.41603 0.75 4.91512 0.75H17.0849C18.584 0.75 19.8086 1.95786 19.8086 3.45934C19.8086 4.00972 19.9532 4.55089 20.2287 5.02939L21.2149 6.74274C21.4737 7.19195 21.6839 7.55669 21.7347 8.16669C21.7553 8.41456 21.7576 8.62312 21.726 8.82441C21.6958 9.0172 21.6381 9.1717 21.5956 9.2854L21.5894 9.3023C21.0565 10.7329 19.6723 11.75 18.0513 11.75C16.695 11.75 15.5023 11.037 14.8374 9.9644C13.9338 11.0575 12.5446 11.75 11 11.75C9.4554 11.75 8.06617 11.0575 7.16259 9.9644C6.49773 11.037 5.30506 11.75 3.94875 11.75C2.32768 11.75 0.943552 10.7329 0.410652 9.3022L0.404362 9.2854C0.361902 9.1717 0.304212 9.0172 0.273972 8.82441C0.242402 8.62312 0.244692 8.41457 0.265332 8.16669C0.316132 7.55668 0.526282 7.19195 0.785092 6.74274L1.77133 5.02939C2.04677 4.55089 2.19143 4.00972 2.19143 3.45934Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16.5C5 15.9477 5.44772 15.5 6 15.5H10C10.5523 15.5 11 15.9477 11 16.5C11 17.0523 10.5523 17.5 10 17.5H6C5.44772 17.5 5 17.0523 5 16.5Z" fill="#A7A7A7" />
                                                </svg>}
                                        </span> Plans
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${Active == 'Payments' ? 'active' : ''}`} href="/Admin/Payments">
                                        <span>
                                            {Active == 'Payments' ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.125C11.5523 0.125 12 0.57272 12 1.125V5.125C12 5.67728 11.5523 6.125 11 6.125C10.4477 6.125 10 5.67728 10 5.125V1.125C10 0.57272 10.4477 0.125 11 0.125ZM15 1.125C15.5523 1.125 16 1.57272 16 2.125V4.125C16 4.67728 15.5523 5.125 15 5.125C14.4477 5.125 14 4.67728 14 4.125V2.125C14 1.57272 14.4477 1.125 15 1.125ZM7 3.125C7.55228 3.125 8 3.57272 8 4.125V6.125C8 6.67728 7.55228 7.125 7 7.125C6.44772 7.125 6 6.67728 6 6.125V4.125C6 3.57272 6.44772 3.125 7 3.125Z" fill="white" />
                                                <path opacity="0.4" d="M3.16745 5.06597C3.42629 5.12525 3.69375 5.17674 3.9687 5.21977C4.22682 5.26016 4.35588 5.28035 4.42794 5.36457C4.5 5.44878 4.5 5.57495 4.5 5.82729V6.12456C4.5 7.50527 5.61929 8.62456 7 8.62456C7.76159 8.62456 8.44364 8.28401 8.90218 7.74688C9.1751 7.4272 9.3115 7.26736 9.4047 7.2441C9.4978 7.22084 9.6936 7.29786 10.0852 7.45189C10.3685 7.56334 10.6771 7.62456 11 7.62456C11.7616 7.62456 12.4436 7.28401 12.9022 6.74688C13.1751 6.4272 13.3115 6.26736 13.4047 6.2441C13.4978 6.22084 13.6936 6.29786 14.0852 6.45189C14.3685 6.56334 14.6771 6.62456 15 6.62456C16.3807 6.62456 17.5 5.50527 17.5 4.12456V4.11277C17.5 3.80009 17.5 3.64375 17.6041 3.55399C17.7082 3.46424 17.8583 3.48655 18.1585 3.53116C18.5042 3.58253 18.841 3.646 19.1674 3.72076C20.0845 3.93081 20.7825 4.3366 21.2218 5.01188C21.6428 5.65896 21.75 6.44155 21.75 7.2384V18.0105C21.75 19.5777 20.159 20.4867 18.8326 20.1829C17.9701 19.9854 17.012 19.8744 16 19.8744C14.1744 19.8744 12.5352 20.2352 11.3174 20.804C9.8702 21.4799 8.00849 21.8744 6 21.8744C4.88016 21.8744 3.80949 21.7519 2.83255 21.5281C1.95689 21.3275 1.26524 20.9356 0.81644 20.2732C0.38242 19.6326 0.25 18.846 0.25 18.0105V7.2384C0.25 5.67122 1.841 4.76215 3.16745 5.06597Z" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 12.625C14 14.2819 12.6569 15.625 11 15.625C9.3431 15.625 8 14.2819 8 12.625C8 10.9681 9.3431 9.625 11 9.625C12.6569 9.625 14 10.9681 14 12.625ZM5.5 13.625C5.5 13.0727 5.05228 12.625 4.5 12.625C3.94772 12.625 3.5 13.0727 3.5 13.625V13.634C3.5 14.1863 3.94772 14.634 4.5 14.634C5.05228 14.634 5.5 14.1863 5.5 13.634V13.625ZM17.5 10.6172C18.0523 10.6172 18.5 11.0649 18.5 11.6172V11.6262C18.5 12.1785 18.0523 12.6262 17.5 12.6262C16.9477 12.6262 16.5 12.1785 16.5 11.6262V11.6172C16.5 11.0649 16.9477 10.6172 17.5 10.6172Z" fill="white" />
                                            </svg>
                                                :
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.125C11.5523 0.125 12 0.57272 12 1.125V5.125C12 5.67728 11.5523 6.125 11 6.125C10.4477 6.125 10 5.67728 10 5.125V1.125C10 0.57272 10.4477 0.125 11 0.125ZM15 1.125C15.5523 1.125 16 1.57272 16 2.125V4.125C16 4.67728 15.5523 5.125 15 5.125C14.4477 5.125 14 4.67728 14 4.125V2.125C14 1.57272 14.4477 1.125 15 1.125ZM7 3.125C7.55228 3.125 8 3.57272 8 4.125V6.125C8 6.67728 7.55228 7.125 7 7.125C6.44772 7.125 6 6.67728 6 6.125V4.125C6 3.57272 6.44772 3.125 7 3.125Z" fill="#A7A7A7" />
                                                    <path opacity="0.4" d="M3.16745 5.06597C3.42629 5.12525 3.69375 5.17674 3.9687 5.21977C4.22682 5.26016 4.35588 5.28035 4.42794 5.36457C4.5 5.44878 4.5 5.57495 4.5 5.82729V6.12456C4.5 7.50527 5.61929 8.62456 7 8.62456C7.76159 8.62456 8.44364 8.28401 8.90218 7.74688C9.1751 7.4272 9.3115 7.26736 9.4047 7.2441C9.4978 7.22084 9.6936 7.29786 10.0852 7.45189C10.3685 7.56334 10.6771 7.62456 11 7.62456C11.7616 7.62456 12.4436 7.28401 12.9022 6.74688C13.1751 6.4272 13.3115 6.26736 13.4047 6.2441C13.4978 6.22084 13.6936 6.29786 14.0852 6.45189C14.3685 6.56334 14.6771 6.62456 15 6.62456C16.3807 6.62456 17.5 5.50527 17.5 4.12456V4.11277C17.5 3.80009 17.5 3.64375 17.6041 3.55399C17.7082 3.46424 17.8583 3.48655 18.1585 3.53116C18.5042 3.58253 18.841 3.646 19.1674 3.72076C20.0845 3.93081 20.7825 4.3366 21.2218 5.01188C21.6428 5.65896 21.75 6.44155 21.75 7.2384V18.0105C21.75 19.5777 20.159 20.4867 18.8326 20.1829C17.9701 19.9854 17.012 19.8744 16 19.8744C14.1744 19.8744 12.5352 20.2352 11.3174 20.804C9.8702 21.4799 8.00849 21.8744 6 21.8744C4.88016 21.8744 3.80949 21.7519 2.83255 21.5281C1.95689 21.3275 1.26524 20.9356 0.81644 20.2732C0.38242 19.6326 0.25 18.846 0.25 18.0105V7.2384C0.25 5.67122 1.841 4.76215 3.16745 5.06597Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 12.625C14 14.2819 12.6569 15.625 11 15.625C9.3431 15.625 8 14.2819 8 12.625C8 10.9681 9.3431 9.625 11 9.625C12.6569 9.625 14 10.9681 14 12.625ZM5.5 13.625C5.5 13.0727 5.05228 12.625 4.5 12.625C3.94772 12.625 3.5 13.0727 3.5 13.625V13.634C3.5 14.1863 3.94772 14.634 4.5 14.634C5.05228 14.634 5.5 14.1863 5.5 13.634V13.625ZM17.5 10.6172C18.0523 10.6172 18.5 11.0649 18.5 11.6172V11.6262C18.5 12.1785 18.0523 12.6262 17.5 12.6262C16.9477 12.6262 16.5 12.1785 16.5 11.6262V11.6172C16.5 11.0649 16.9477 10.6172 17.5 10.6172Z" fill="#A7A7A7" />
                                                </svg>}
                                        </span> Payments
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${Active == 'Gateways' ? 'active' : ''}`} href="/Admin/Gateways">
                                        <span>
                                            {Active == 'Gateways' ? <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" d="M21.75 9.0528C21.75 10.7768 21.75 12.1427 21.6124 13.2222C21.4707 14.3333 21.1732 15.239 20.507 15.9862C20.3093 16.208 20.0919 16.4125 19.8574 16.5977C19.074 17.2164 18.1316 17.4901 16.9694 17.6214C15.8308 17.75 14.3866 17.75 12.5475 17.75H9.4525C7.61345 17.75 6.16917 17.75 5.03058 17.6214C3.86842 17.4901 2.926 17.2164 2.14263 16.5977C1.90811 16.4125 1.69068 16.2079 1.49298 15.9862C0.82681 15.239 0.52932 14.3333 0.38763 13.2222C0.24998 12.1427 0.24999 10.7767 0.25 9.0527V8.9473C0.25 8.3715 0.24999 7.8357 0.25512 7.3371C0.25795 7.0614 0.25937 6.92356 0.34704 6.83678C0.43472 6.75 0.57361 6.75 0.85139 6.75H21.1486C21.4264 6.75 21.5653 6.75 21.653 6.83678C21.7406 6.92356 21.7421 7.0614 21.7449 7.3371C21.75 7.8356 21.75 8.3715 21.75 8.9473V9.0528Z" fill="white" />
                                                <path d="M12.5485 0.25C14.3875 0.24999 15.8317 0.24998 16.9703 0.3786C18.1325 0.50988 19.0749 0.78362 19.8583 1.40229C20.0928 1.58749 20.3102 1.79205 20.5079 2.0138C21.1741 2.76101 21.4716 3.66669 21.6133 4.77785C21.6296 4.9057 21.6377 4.96963 21.6231 5.02579C21.5996 5.11553 21.5308 5.19375 21.4447 5.22835C21.3909 5.25 21.3235 5.25 21.1887 5.25H0.813142C0.678362 5.25 0.610972 5.25 0.557122 5.22835C0.471052 5.19375 0.402182 5.11554 0.378742 5.02579C0.364082 4.96963 0.372232 4.9057 0.388542 4.77785C0.530232 3.66669 0.827722 2.76101 1.49389 2.0138C1.69159 1.79205 1.90902 1.58749 2.14354 1.40229C2.92692 0.78362 3.86933 0.50988 5.03149 0.3786C6.17008 0.24998 7.61437 0.24999 9.4534 0.25H12.5485Z" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 13C9.25 12.5858 9.5858 12.25 10 12.25H11.5C11.9142 12.25 12.25 12.5858 12.25 13C12.25 13.4142 11.9142 13.75 11.5 13.75H10C9.5858 13.75 9.25 13.4142 9.25 13Z" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 13C13.75 12.5858 14.0858 12.25 14.5 12.25H18C18.4142 12.25 18.75 12.5858 18.75 13C18.75 13.4142 18.4142 13.75 18 13.75H14.5C14.0858 13.75 13.75 13.4142 13.75 13Z" fill="white" />
                                            </svg>
                                                :
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M21.75 9.0528C21.75 10.7768 21.75 12.1427 21.6124 13.2222C21.4707 14.3333 21.1732 15.239 20.507 15.9862C20.3093 16.208 20.0919 16.4125 19.8574 16.5977C19.074 17.2164 18.1316 17.4901 16.9694 17.6214C15.8308 17.75 14.3866 17.75 12.5475 17.75H9.4525C7.61345 17.75 6.16917 17.75 5.03058 17.6214C3.86842 17.4901 2.926 17.2164 2.14263 16.5977C1.90811 16.4125 1.69068 16.2079 1.49298 15.9862C0.82681 15.239 0.52932 14.3333 0.38763 13.2222C0.24998 12.1427 0.24999 10.7767 0.25 9.0527V8.9473C0.25 8.3715 0.24999 7.8357 0.25512 7.3371C0.25795 7.0614 0.25937 6.92356 0.34704 6.83678C0.43472 6.75 0.57361 6.75 0.85139 6.75H21.1486C21.4264 6.75 21.5653 6.75 21.653 6.83678C21.7406 6.92356 21.7421 7.0614 21.7449 7.3371C21.75 7.8356 21.75 8.3715 21.75 8.9473V9.0528Z" fill="#A7A7A7" />
                                                    <path d="M12.5485 0.25C14.3875 0.24999 15.8317 0.24998 16.9703 0.3786C18.1325 0.50988 19.0749 0.78362 19.8583 1.40229C20.0928 1.58749 20.3102 1.79205 20.5079 2.0138C21.1741 2.76101 21.4716 3.66669 21.6133 4.77785C21.6296 4.9057 21.6377 4.96963 21.6231 5.02579C21.5996 5.11553 21.5308 5.19375 21.4447 5.22835C21.3909 5.25 21.3235 5.25 21.1887 5.25H0.813142C0.678362 5.25 0.610972 5.25 0.557122 5.22835C0.471052 5.19375 0.402182 5.11554 0.378742 5.02579C0.364082 4.96963 0.372232 4.9057 0.388542 4.77785C0.530232 3.66669 0.827722 2.76101 1.49389 2.0138C1.69159 1.79205 1.90902 1.58749 2.14354 1.40229C2.92692 0.78362 3.86933 0.50988 5.03149 0.3786C6.17008 0.24998 7.61437 0.24999 9.4534 0.25H12.5485Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 13C9.25 12.5858 9.5858 12.25 10 12.25H11.5C11.9142 12.25 12.25 12.5858 12.25 13C12.25 13.4142 11.9142 13.75 11.5 13.75H10C9.5858 13.75 9.25 13.4142 9.25 13Z" fill="#A7A7A7" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 13C13.75 12.5858 14.0858 12.25 14.5 12.25H18C18.4142 12.25 18.75 12.5858 18.75 13C18.75 13.4142 18.4142 13.75 18 13.75H14.5C14.0858 13.75 13.75 13.4142 13.75 13Z" fill="#A7A7A7" />
                                                </svg>}
                                        </span> Gateways
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <div className="bg-light p-3 rounded mt-3 custom-sidenav-box" style={{ border: '1px solid #0000000A', cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#LogOutConfirm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className='m-0' style={{ fontWeight: 700 }}>
                                        <span className='pe-2'>
                                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M5.95964 4.18436C6.25778 4.63184 6.13597 5.23577 5.68756 5.53329C3.58339 6.92941 2.2 9.3139 2.2 12.0203C2.2 16.3192 5.69218 19.8041 10 19.8041C14.3078 19.8041 17.8 16.3192 17.8 12.0203C17.8 9.3139 16.4166 6.92941 14.3124 5.53329C13.864 5.23577 13.7422 4.63184 14.0404 4.18436C14.3385 3.73689 14.9437 3.61533 15.3921 3.91285C18.0168 5.65432 19.75 8.63513 19.75 12.0203C19.75 17.3939 15.3848 21.75 10 21.75C4.61522 21.75 0.25 17.3939 0.25 12.0203C0.25 8.63513 1.98323 5.65432 4.60789 3.91285C5.0563 3.61533 5.6615 3.73689 5.95964 4.18436Z" fill="#A7A7A7" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.25C10.5523 0.25 11 0.69772 11 1.25V9.25C11 9.8023 10.5523 10.25 10 10.25C9.4477 10.25 9 9.8023 9 9.25V1.25C9 0.69772 9.4477 0.25 10 0.25Z" fill="#A7A7A7" />
                                            </svg>
                                        </span>
                                        Log Out</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
            <div className="modal fade" id="LogOutConfirm" tabindex="-1" aria-labelledby="LogOutConfirmLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ fontWeight: '600' }}>
                                Log Out
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='regenerateCloseBtnModal'></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex Regenerate-Modal-Body">
                                <div className="col">
                                    <h4 className='m-0'>Are you sure you want to log-off?</h4>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex">
                            <button type="button" className="simple-light-btn" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="simple-danger-btn col w-100"
                                onClick={() => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                }}>Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
